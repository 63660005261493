const Router = (function() {
  /**
   * @routes
   * get or set routes
   */
  var routes = []

  /**
   * @addRoute
   * add new Route logic
   */
  function addRoute(route, handler) {
    routes.push({ parts: route.split('/'), handler: handler })
  }
  /**
   * @load
   * load router content from hash url
   */
  function load(route, callback) {
    window.location.hash = route
    return callback && callback(route)
  }

  /**
   * @init
   * routing the content with hash location params
   */
  function init() {
    const path = window.location.hash.substr(1),
      parts = path.split('/'),
      partsLength = parts.length

    for (let i = 0; i < routes.length; i++) {
      const route = routes[i]
      if (route.parts.length === partsLength) {
        var params = []
        for (var j = 0; j < partsLength; j++) {
          if (route.parts[j].substr(0, 1) === ':') {
            params.push(parts[j])
          } else if (route.parts[j] !== parts[j]) {
            break
          }
        }
        if (j === partsLength) {
          route.handler.apply(undefined, params)
          return
        }
      }
    }
  }

  window.onhashchange = init

  return {
    addRoute,
    load,
    init
  }
})()

export default Router
