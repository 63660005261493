import moment from 'moment'
/**
 * Generate option list for "antd select" source data
 * @param {array} listData
 * @param {string} keyName
 * @param {string} keyValue
 */
export const optionListGenerator = (listData, keyName, keyValue) => {
  return (
    (listData && listData.data && listData.data.map(data => ({ name: data[keyName], value: data[keyValue] }))) || []
  )
}

export const optionListDataMaster = (listData, keyName, keyValue) => {
  return (listData && listData[0] && listData.map(data => ({ name: data[keyName], value: data[keyValue] }))) || []
}

export const dateFormatValidator = date => {
  const format = /[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])/ // YYYY-MM-DD
  return format.test(date)
}

export const formatDate = date => {
  let dateFormated = ''
  if (typeof date === 'string' || typeof date === 'object') {
    dateFormated = moment(date).format('DD-MM-YYYY')
  }
  return dateFormated
}

export const formatTime = date => {
  let timeFormated = ''
  if (typeof date === 'string' || typeof date === 'object') {
    timeFormated = new Date(date).toLocaleTimeString()
  }
  return timeFormated
}

export const formatDateTime = date => {
  let timeFormated = moment(date).format('DD-MM-YYYY HH:mm')
  return timeFormated
}

export const formatLocalDateTime = date => {
  return moment(date).format('DD-MM-YYYY HH:mm')
}

export const padZeroStart = num => String(num || 0).padStart(2, '0')

export const fixedNumber = (num, length = 2) => Number(num).toFixed(length)

export function setDataListReducer(state, action, actionType) {
  if (action.type === actionType) {
    const { data, count, paging, reset } = action
    if (reset) return { data: [], count: '', paging: {} }
    const pagingPages = state ? { ...state.paging, ...paging } : paging
    return { data, count, paging: pagingPages }
  }
  return state
}

export function formatCurrency(num) {
  const isMinus = num < 0
  const currencyCode = 'IDR'
  const formatter = new Intl.NumberFormat('en', {
    style: 'currency',
    currency: currencyCode
  })
  const dataPartFormatted = () => formatter.formatToParts(num)

  // return formatted without the currency symbol
  const result =
    num !== null
      ? dataPartFormatted()
          .filter((_, idx) => idx > 1)
          .map(obj => obj.value)
          .join('')
      : ''
  return isMinus ? `-${result.slice(1)}` : result
}

export const nextYearDate = () => {
  const now = new Date()
  const nextYear = new Date(now.setFullYear(now.getFullYear() + 1))
  return new Date(nextYear)
}

export const handleListModule = modules => {
  const sortAsc = arr => arr.sort((a, b) => a.moduleOrder - b.moduleOrder).filter(Boolean)
  const isOrderUnique = arr => {
    const filtered = arr.filter((data, i, array) => {
      return array.filter(num => num === data).length === 1
    })
    return filtered.length === arr.length
  }
  let structuredModule = []
  const TYPE_SUB_MENU = 'Sub Menu'
  if (modules && modules[0]) {
    const mainMenu = modules.filter(module => module.moduleType !== TYPE_SUB_MENU)
    const mainMenuOrder = mainMenu.map(data => data.moduleOrder)
    const mainOrderUnique = isOrderUnique(mainMenuOrder)
    for (let mainIndex = 0; mainIndex < mainMenu.length; mainIndex++) {
      const mainModule = mainMenu[mainIndex]
      let subs = []
      for (let indexModule = 0; indexModule < modules.length; indexModule++) {
        const module = modules[indexModule]
        if (module.moduleParentId === mainModule.moduleId) {
          subs[module.moduleOrder] = module
        }
      }
      if (subs.includes(undefined)) {
        subs = sortAsc(subs)
      }
      mainModule.submenu = subs[0] ? subs : null
      mainOrderUnique ? (structuredModule[mainModule.moduleOrder] = mainModule) : structuredModule.push(mainModule)
    }
  }

  if (structuredModule.includes(undefined)) {
    structuredModule = sortAsc(structuredModule)
  }
  return structuredModule
}
