// reducers hold the store's state (the initialState object defines it)
// reducers also handle plain object actions and modify their state (immutably) accordingly
// this is the only way to change the store's state
// the other exports in this file are selectors, which is business logic that digests parts of the store's state
// for easier consumption by views

import { actionTypes } from './action'

/**
 * default set reducer that will be always return data
 * @param {object} state
 * @param {object} action
 * @param {string} actionType
 */
function setDataReducer(state, action, actionType) {
  if (action.type === actionType) {
    return { data: action.data }
  }
  return state
}

function setDataListReducer(state, action, actionType) {
  if (action.type === actionType) {
    const { data, count, paging, reset } = action
    if (reset) return { data: [], count: '', paging: {} }
    const pagingPages = state ? { ...state.paging, ...paging } : paging
    return { data, count, paging: pagingPages }
  }
  return state
}

export const accountRateReducer = (state = null, action) =>
  setDataReducer(state, action, actionTypes.ACCOUNT_RATE_SUCCESS)

export const accountRateDropdownReducer = (state = null, action) =>
  setDataReducer(state, action, actionTypes.ACCOUNT_RATE_DROPDOWN_SUCCESS)

export const listAccountRateReducer = (state = null, action) =>
  setDataListReducer(state, action, actionTypes.LIST_ACCOUNT_RATE_SUCCESS)
