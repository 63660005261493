import React from 'react'
import { node, string } from 'prop-types'
import { Button } from 'antd'
import './style.css'

const button = ({ children, type, ...rest }) => {
  const customClass = () => {
    if (type === 'secondary') return 'button-secondary'
    return ''
  }
  return (
    <Button type={type} className={`button-base ${customClass()}`} {...rest}>
      {children}
    </Button>
  )
}

button.propTypes = {
  children: node,
  type: string
}

export default button
