import { Layout } from 'antd'

import MainLayout from './mainLayout'
import MainContent from './content'
import Sider from './sidebar'
import Headers from './header'
import Footers from './footer'

export default MainLayout

export const Content = MainContent
export const Layouts = Layout
export const Sidebar = Sider
export const Header = Headers
export const Footer = Footers
