// reducers hold the store's state (the initialState object defines it)
// reducers also handle plain object actions and modify their state (immutably) accordingly
// this is the only way to change the store's state
// the other exports in this file are selectors, which is business logic that digests parts of the store's state
// for easier consumption by views

import { actionTypes } from './action'

function setDataListReducer(state, action, actionType) {
  if (action.type === actionType) {
    const { data, count, paging, reset } = action
    if (reset) return data === null ? data : { data: [], count: '', paging: {} }
    const pagingPages = state ? { ...state.paging, ...paging } : paging
    return { data, count, paging: pagingPages }
  }
  return state
}

function setDataGenerateNtrExcelReducer(state, action, actionType) {
  if (action.type === actionType) {
    return { data: action.data }
  }
  return state
}

export const listNetTradingResultAccountReducer = (state = null, action) =>
  setDataListReducer(state, action, actionTypes.LIST_NET_TRADING_RESULT_ACCOUNT_SUCCESS)

export const listNetTradingResultBranchReducer = (state = null, action) =>
  setDataListReducer(state, action, actionTypes.LIST_NET_TRADING_RESULT_BRANCH_SUCCESS)

export const listNetTradingResultAccountTypeReducer = (state = null, action) =>
  setDataListReducer(state, action, actionTypes.LIST_NET_TRADING_RESULT_ACCOUNT_TYPE_SUCCESS)

export const listNetTradingResultAccountingAccountReducer = (state = null, action) =>
  setDataListReducer(state, action, actionTypes.LIST_NET_TRADING_RESULT_ACCOUNTING_ACCOUNT_SUCCESS)

export const listNetTradingResultAccountingBranchReducer = (state = null, action) =>
  setDataListReducer(state, action, actionTypes.LIST_NET_TRADING_RESULT_ACCOUNTING_BRANCH_SUCCESS)

export const listNetTradingResultAccountingDealNumberReducer = (state = null, action) =>
  setDataListReducer(state, action, actionTypes.LIST_NET_TRADING_RESULT_ACCOUNTING_DEAL_NUMBER_SUCCESS)
export const getFileExcel = (state = null, action) =>
  setDataGenerateNtrExcelReducer(state, action, actionTypes.NET_TRADING_RESULT_DOWNLOAD_SUCCESS)

function setDataReducer(state, action, actionType) {
  if (action.type === actionType) {
    return { data: action.data }
  }
  return state
}
export const netTradingResultTimeReducer = (state = null, action) =>
  setDataReducer(state, action, actionTypes.NET_TRADING_RESULT_TIME_SUCCESS)
