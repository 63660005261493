// reducers hold the store's state (the initialState object defines it)
// reducers also handle plain object actions and modify their state (immutably) accordingly
// this is the only way to change the store's state
// the other exports in this file are selectors, which is business logic that digests parts of the store's state
// for easier consumption by views

import { actionTypes } from './action'
import { setDataListReducer } from 'utils'

const toCamel = keyString => {
  return keyString.replace(/([-_][a-z])/gi, $1 => {
    return $1
      .toUpperCase()
      .replace('-', '')
      .replace('_', '')
  })
}

export const masterDataReducer = (state = {}, action) => {
  if (action.type.includes('MASTER_SUCCESS')) {
    let key = action.type.slice(0, -15).toLowerCase()
    key = toCamel(key)
    const keyMap = `${key}Map`
    return {
      ...state,
      [key]: action.data,
      [keyMap]: action.dataMap
    }
  }
  return state
}

export const masterUserReducer = (state = null, action) =>
  setDataListReducer(state, action, actionTypes.MASTER_USER_SUCCESS)

export const masterModuleReducer = (state = null, action) =>
  setDataListReducer(state, action, actionTypes.MASTER_MODULE_SUCCESS)
