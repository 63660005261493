export const clearingCache = (reload = true) => {
  if (window && window.caches && caches.keys) {
    // Service worker cache should be cleared with caches.delete()
    caches.keys().then(function(names) {
      const cacheName = names || []
      for (let name of cacheName) caches.delete(name)
      // delete browser cache and hard reload
      reload && window.location.reload(true)
    })
  }
}

async function refreshCache() {
  const dev = process.env.NODE_ENV === 'development'
  let newUpdateAvailable = false
  if (dev) {
    const VERSION_BUILD_KEY = '__v_build_'
    const getVersion = () => localStorage.getItem(VERSION_BUILD_KEY)
    const setVersion = value => localStorage.setItem(VERSION_BUILD_KEY, value)
    await fetch('./meta.json')
      .then(res => res.json())
      .then(newMeta => {
        const currentVersion = getVersion()
        if (currentVersion) {
          const hasNew = currentVersion !== newMeta.version
          if (hasNew) {
            setVersion(newMeta.version)
            newUpdateAvailable = true
          }
        } else {
          setVersion(newMeta.version)
        }
      })
      .catch(err => console.error('Error getting the meta.json, ', err.message))
  }
  return newUpdateAvailable
}

export default refreshCache
