import './style.css'

import { Col, Form, Row, Typography } from 'antd'
import Helmet from 'components/helmet'
import Layout from 'components/layout'
import { useStateDefault, useStateValue, usePrevious } from 'context'
import { changePassword, actionTypes, actionLogout } from 'context/auth/action'
import { func, object } from 'prop-types'
import React from 'react'
import { Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import Button from 'components/button'
import Input from 'components/input'

const { Title } = Typography

function LoginPage(props) {
  const { t } = useTranslation() // t ->> translate function to show a message by language chosen
  const tKey = 'pages.newPassword.'
  const [nextPath, setnextPath] = React.useState('/home')
  const [userLogin, dispatch] = useStateValue('userProfile')
  const [err, loading] = useStateDefault(actionTypes.CHANGE_PASSWORD)
  const handleSubmit = e => {
    e.preventDefault()
    props.form.validateFields((err, values) => {
      if (!err) {
        changePassword(dispatch, values)
      } else {
        toast.error(t('default.formRequired'))
      }
    })
  }

  const setPathRedirect = () => {
    const url = new URL(window.location.href)

    const path = url.searchParams.get('from')
    if (path) {
      setnextPath(path)
    }
  }

  const prevLoading = usePrevious(loading)

  React.useEffect(() => {
    const url = new URL(window.location.href)
    const path = url.searchParams.get('from')
    if (path && nextPath !== path) {
      setPathRedirect()
    }
    if (!err && !loading && loading !== prevLoading && prevLoading) {
      toast.success('Password has been successfully updated', {
        onClose: () => {
          actionLogout(dispatch)
          props.history.push('/')
        }
      })
    }
  })

  const compareToFirstPassword = (rule, value, callback) => {
    const { form } = props
    if (value && value !== form.getFieldValue('newPassword')) {
      callback('The passwords that you enter is inconsistent!')
    } else {
      callback()
    }
  }

  const compareToSecondPassword = (rule, value, callback) => {
    const { form } = props
    if (form.getFieldValue('newPasswordReEnter') && form.getFieldValue('newPasswordReEnter').length >= 8) {
      if (value && value !== form.getFieldValue('newPasswordReEnter')) {
        form.validateFields(['newPasswordReEnter'], { force: true })
      }
    }
    callback()
  }

  const strongPassword = (rule, value, callback) => {
    const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})')
    const result = strongRegex.test(value)
    if (!result) {
      callback(
        'The passwords have to contains 1 lowercase, 1 uppercase' +
          ' 1 numeric, 1 special character & must be 8 characters or longer'
      )
    } else {
      callback()
    }
  }

  const { getFieldDecorator } = props.form
  const newPassword = userLogin && userLogin.data && userLogin.data.newPasswordRequired
  return (
    <Layout>
      {!newPassword && <Redirect to="/" />}
      <div className={`root-login`}>
        <Helmet>
          <title>{t(`${tKey}pageTitle`)}</title>
        </Helmet>
        <Row type="flex" justify="center" align="middle">
          <Col span={16} md={10} xl={6}>
            <div style={{ position: 'relative', bottom: 34 }}>
              <Title style={{ color: '#fff' }} level={3}>
                Need To Change Password
              </Title>
              <Typography style={{ color: 'rgba(199, 199, 199, 0.65)' }}>Please change your password</Typography>
            </div>
            <Form onSubmit={handleSubmit} className="login-form">
              <label
                style={{
                  top: -2,
                  textTransform: 'capitalize',
                  color: '#fff',
                  position: 'relative'
                }}
                htmlFor="normal_login_password"
              >
                Current password
              </label>
              <Form.Item>
                {getFieldDecorator('currentPassword', {
                  rules: [
                    {
                      required: true,
                      message: 'New password is required'
                    }
                  ]
                })(
                  <Input
                    // prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    type="password"
                    placeholder="Default Password: DDMMYYYY"
                  />
                )}
              </Form.Item>
              <label
                style={{
                  top: -2,
                  textTransform: 'capitalize',
                  color: '#fff',
                  position: 'relative'
                }}
                htmlFor="normal_login_password"
              >
                New password
              </label>
              <Form.Item hasFeedback>
                {getFieldDecorator('newPassword', {
                  rules: [
                    {
                      required: true,
                      message: 'New password is required'
                    },
                    {
                      validator: strongPassword
                    },
                    {
                      validator: compareToSecondPassword
                    }
                  ]
                })(
                  <Input
                    // prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    type="password"
                    placeholder="******"
                  />
                )}
              </Form.Item>
              <label
                style={{
                  top: -2,
                  textTransform: 'capitalize',
                  color: '#fff',
                  position: 'relative'
                }}
                htmlFor="normal_login_password"
              >
                Re-enter your new password
              </label>
              <Form.Item hasFeedback>
                {getFieldDecorator('newPasswordReEnter', {
                  rules: [
                    {
                      required: true,
                      message: `You need to re-enter the new password`
                    },
                    {
                      validator: compareToFirstPassword
                    }
                  ]
                })(
                  <Input
                    // prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    type="password"
                    placeholder="******"
                    onPressEnter={handleSubmit}
                  />
                )}
              </Form.Item>

              <Col span={24}>
                <Button style={{ marginTop: 14 }} loading={loading} type="primary" htmlType="submit">
                  {t(`${tKey}resetButton`).toUpperCase()}
                </Button>
                <div
                  style={{
                    textAlign: 'center',
                    color: '#383838',
                    fontSize: '12px',
                    margin: ' 15px 0px'
                  }}
                >
                  <span
                    onClick={() => {
                      actionLogout(dispatch)
                      props.history.push('/')
                    }}
                    style={{ cursor: 'pointer', color: '#bbb' }}
                  >
                    {t(`${tKey}back`)}
                  </span>
                </div>
              </Col>
            </Form>
          </Col>
        </Row>
      </div>
    </Layout>
  )
}

LoginPage.propTypes = {
  loginUser: func,
  form: object,
  history: object
}

const WrappedLoginPage = Form.create({ name: 'normal_login' })(LoginPage)

export default WrappedLoginPage
