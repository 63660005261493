import React, { useRef } from 'react'
import { Modal, Carousel, Empty } from 'antd'
import { array, string, object, number, bool, func } from 'prop-types'
import { usePrevious, useStateValue } from 'context'
import { viewNotificationType } from 'context/notification/action'
import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'

const NewsNotification = ({ visible, messages, onClose }) => {
  const [components, setComponents] = React.useState([])
  const slider = useRef()
  const [, dispatch] = useStateValue('user')
  const prevMessages = usePrevious(messages)
  const prevVisible = usePrevious(visible)
  React.useEffect(() => {
    const gotMessages = messages && messages !== prevMessages

    if (gotMessages) {
      const messagesToComponents = messages.map(function NewsItem(item, index) {
        return (
          <div key={index}>
            <h2 style={{ fontSize: '2.5rem', textAlign: 'left' }}>{item.subject}</h2>
            <SimpleBar style={{ maxHeight: 400 }}>
              <div
                style={{ display: 'block', width: '100%', textAlign: 'left' }}
                dangerouslySetInnerHTML={{ __html: item.content }}
              />
            </SimpleBar>
          </div>
        )
      })
      setComponents(messagesToComponents)
    }
  }, [dispatch, messages, prevMessages, prevVisible, visible])

  return (
    <Modal
      title={`News`}
      okText={`Next`}
      onOk={() => slider.current.next()}
      cancelText={`Close`}
      onCancel={onClose}
      visible={visible}
      destroyOnClose={true}
      className="notification"
      closable={false}
      maskClosable={false}
      okButtonProps={{
        disabled: components.length === 1
      }}
    >
      {components.length ? (
        <Carousel
          ref={ref => {
            slider.current = ref
          }}
          infinite={true}
          dotPosition="top"
        >
          {components.map(Component => Component)}
        </Carousel>
      ) : (
        <Empty />
      )}
    </Modal>
  )
}

NewsNotification.propTypes = {
  messages: array,
  userName: string,
  duration: number,
  router: object,
  visible: bool,
  onClose: func
}

export default NewsNotification
