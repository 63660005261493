import './style.css'

import { Form } from 'antd'
import Helmet from 'components/helmet'
import Layout from 'components/layout'
import config from 'config'
import { useStateDefault, usePrevious, useStateValue, AuthContext } from 'context'
import { actionLogin, forgotPasswordFinish, activationAccount, actionTypes } from 'context/auth/action'
import { func, object } from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'
import { isLogin } from 'utils/userData'
import { toast } from 'react-toastify'
import Button from 'components/button'
import Logo from 'assets/image/logo.png'
import Particles from 'react-particles-js'
import { reLogout } from '../../utils/logout'
// import { ReCaptcha } from 'react-recaptcha-v3'
const particleParams = {
  particles: {
    number: {
      value: 100,
      density: {
        enable: false
      }
    },
    size: {
      value: 3,
      random: true,
      anim: {
        speed: 5,
        size_min: 0.3
      }
    },
    color: {
      value: '#ffffff'
    },
    opacity: {
      value: 0.5,
      random: false,
      anim: {
        enable: false,
        speed: 1,
        opacity_min: 0.1,
        sync: false
      }
    },
    line_linked: {
      enable: false
    },
    move: {
      random: true,
      speed: 1,
      direction: 'top',
      out_mode: 'out'
    }
  },
  interactivity: {
    events: {
      onhover: {
        enable: true,
        mode: 'bubble'
      },
      onclick: {
        enable: true,
        mode: 'repulse'
      }
    },
    modes: {
      bubble: {
        distance: 250,
        duration: 2,
        size: 0,
        opacity: 0
      },
      repulse: {
        distance: 400,
        duration: 4
      }
    }
  }
}

function LoginPage(props) {
  const { t } = useTranslation() // t ->> translate function to show a message by language chosen
  const tKey = 'pages.login.'
  const [nextPath, setnextPath] = React.useState('/home')
  const [captchaLoading, setCaptchaLoading] = React.useState(false)
  const { history } = props
  const [userLogin] = useStateValue('userProfile')
  const { login, setLoginStatus } = React.useContext(AuthContext)
  const [, loadingUserLogin, dispatch] = useStateDefault(actionTypes.USER_LOGIN)
  const [errActivateAccount, loadingActivateAccount] = useStateDefault(actionTypes.ACTIVATE_ACCOUNT)
  const [errForgotPassword, loadingForgotPassword] = useStateDefault(actionTypes.FORGOT_PASSWORD)
  const loading = loadingUserLogin || captchaLoading

  const handleSubmit = e => {
    e.preventDefault()
    const { grecaptcha } = window
    if (grecaptcha) {
      setCaptchaLoading(true)
      grecaptcha.ready(function() {
        grecaptcha.execute(config.reCaptchaSiteKey, { action: 'submit' }).then(function(token) {
          setCaptchaLoading(false)
          // Add your logic to submit to your backend server here.
          props.form.validateFields((err, values) => {
            if (!err) {
              actionLogin(dispatch, values)
            } else {
              toast.error(t('default.formRequired'))
            }
          })
        })
      })
    }
  }

  const handleSubmitWithoutRecaptcha = e => {
    e.preventDefault()
    props.form.validateFields((err, values) => {
      if (!err) {
        actionLogin(dispatch, values)
      } else {
        toast.error(t('default.formRequired'))
      }
    })
  }

  const setPathRedirect = () => {
    const url = new URL(window.location.href)

    const path = url.searchParams.get('from')
    if (path) {
      setnextPath(path)
    }
  }

  const clearSearch = () => {
    if (window) {
      const { origin, pathname, hash } = window.location
      const clearUrl = origin + pathname + hash
      window.location.replace(clearUrl)
    }
  }

  const handleActivationKey = key => {
    activationAccount(dispatch, { key })
  }

  const handleResetKey = key => {
    forgotPasswordFinish(dispatch, { key })
  }

  React.useEffect(() => {
    reLogout()
  }, [])

  const pathname = history.location.pathname
  const prevPathname = usePrevious(pathname)
  const prevLoadingActivateAccount = usePrevious(loadingActivateAccount)
  const prevLoadingForgotPassword = usePrevious(loadingForgotPassword)
  React.useEffect(() => {
    const url = new URL(window.location.href)
    const path = url.searchParams.get('from')
    const status = url.searchParams.get('status')
    const key = url.searchParams.get('key')

    if (pathname && prevPathname !== pathname) {
      if (status === 'activation' && key) {
        handleActivationKey(key)
      }
      if (status === 'reset' && key) {
        handleResetKey(key)
      }
    }

    if (path && nextPath !== path) {
      setPathRedirect()
    }

    if (
      !errActivateAccount & !loadingActivateAccount &&
      loadingActivateAccount !== prevLoadingActivateAccount &&
      prevLoadingActivateAccount
    ) {
      toast.success('Your account has been activated ', { autoClose: 2000, onClose: clearSearch })
    }
    if (
      !errForgotPassword & !loadingForgotPassword &&
      loadingForgotPassword !== prevLoadingForgotPassword &&
      prevLoadingForgotPassword
    ) {
      toast.success('Your password has been reset, please login using your birth of date password ', {
        autoClose: 2000,
        onClose: clearSearch
      })
    }
  })

  const { getFieldDecorator } = props.form
  const newPassword = userLogin && userLogin.data && userLogin.data.newPasswordRequired
  if (isLogin()) {
    setLoginStatus(true)
    return login && <Redirect to={nextPath} />
  }
  return (
    <Layout>
      <Helmet>
        <title>Login - Agrodana Futures</title>
      </Helmet>
      <Particles params={particleParams} style={{ position: 'absolute', zIndex: 0 }} />
      {newPassword && <Redirect to="/new-password" />}
      <div className="main-container">
        <div className="container-login">
          <div className="left-morph">
            <div className="login-box" style={{ zIndex: 2 }}>
              <div className="logo-container">
                <img src={Logo} alt="" />
              </div>
              <div className="caption-login">Foreign Exchange, Commodities and Asian & World Stock Market Indices</div>

              <Form onSubmit={handleSubmit} className="login-form">
                <Form.Item>
                  {getFieldDecorator('username', {
                    rules: [
                      {
                        required: true,
                        message: t(`${tKey}form.usernameMessageRequired`)
                      },
                      {
                        type: 'email',
                        message: 'The input is not valid e-mail!'
                      }
                    ]
                  })(
                    <div className="mb5px">
                      <input
                        disabled={loading}
                        className="form-control"
                        type="text"
                        name="username"
                        placeholder="Email / Username"
                      />
                    </div>
                  )}
                </Form.Item>
                <Form.Item style={{ marginBottom: '1.5rem' }}>
                  {getFieldDecorator('password', {
                    rules: [
                      {
                        required: true,
                        message: t(`${tKey}form.passwordMessageRequired`)
                      }
                    ]
                  })(
                    <div className="mb10px">
                      <input
                        disabled={loading}
                        className="form-control"
                        type="password"
                        name="username"
                        placeholder="Password"
                      />
                    </div>
                  )}
                </Form.Item>
                <Button
                  loading={loading}
                  style={{ width: 213 }}
                  type="button"
                  htmlType="submit"
                  className="btnAgrodana btnAgrodana-default mb1"
                >
                  Sign in
                </Button>
              </Form>
              {/* <ReCaptcha sitekey={config.reCaptchaSiteKey} action="submit_login" /> */}
              <div className="clearfix forgot-wrapper">
                <div className="pull-right">
                  <div
                    onClick={() => history.push('/forgot-password')}
                    style={{ cursor: 'pointer' }}
                    className="fz11 forgot-psw"
                  >
                    Forgot your password?
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

LoginPage.propTypes = {
  loginUser: func,
  form: object,
  history: object
}

const WrappedLoginPage = Form.create({ name: 'normal_login' })(LoginPage)

export default WrappedLoginPage
