import React from 'react'
import { Link } from 'react-router-dom'
import { Layout, Row, Col, Avatar, Badge } from 'antd'
import { useStateValue } from 'context'
import { TiBell } from 'react-icons/ti'
import Notification from '../notification'
import NotificationNews from '../notification/indexs'
import { listNotification } from 'context/prospect/action'
import { toggleViewNotification, viewNotificationType } from 'context/notification/action'
import { useScreenBreakpoint } from 'context'
import MobileMenu from '../menu/mobileMenu'
const { Header } = Layout

const Headers = () => {
  const breakpoints = useScreenBreakpoint()
  const [userProfile] = useStateValue('userProfile')
  const [viewNotification, dispatch] = useStateValue('viewNotification')
  const [listNewsNotification] = useStateValue('listNewsNotification')
  const [viewType] = useStateValue('viewNotifType')
  const dataNotification = listNewsNotification?.data || []
  const user = userProfile && userProfile.data ? userProfile.data : {}
  const name = user.employeeName || 'User'
  const firstName = name.split(' ')[0]

  const [listProspectNotification] = useStateValue('listProspectNotification')
  // const [listNewsNotification] = useStateValue('listNewsNotification')

  const [openMobileMenu, setOpenMobileMenu] = React.useState(false)
  const hasSidebar = breakpoints['600px']?.matches

  const notificationMessages = listProspectNotification?.data
    ? Object.keys(listProspectNotification.data)
        .filter(key => listProspectNotification.data[key].length)
        .map(key => ({ key, list: listProspectNotification.data[key] }))
    : []

  const loadNotification = () => {
    listNotification(dispatch, {})
    toggleViewNotification(dispatch, true)
  }

  const BurgerMenu = props => (
    <>
      <section {...props} className="menu-icon-wrapper">
        <div id="menu-icon"></div>
        <div id="menu-icon"></div>
        <div id="menu-icon"></div>
      </section>
      {!hasSidebar && <MobileMenu collapsed={openMobileMenu} handleCollapse={setOpenMobileMenu} />}
    </>
  )

  return (
    <Header
      style={{
        background: '#fff',
        padding: 0,
        boxShadow: '0px 1px 6px 1px rgba(200,200,200, 0.1)',
        borderRadius: '10px'
      }}
    >
      <div style={{ padding: '0 24px', background: '#fff' }}>
        <Row type="flex" justify="space-between">
          <section style={{ display: 'flex' }}>
            <BurgerMenu onClick={() => setOpenMobileMenu(true)} />
          </section>
          <section style={{ display: 'flex' }}>
            <Col style={{ paddingRight: '12px', textTransform: 'capitalize' }}>Hi, {firstName}</Col>
            <Col style={{ paddingRight: '12px', cursor: 'pointer' }}>
              <Link to="/my-profile">
                <Avatar size="large">{name.slice(0, 1).toUpperCase()}</Avatar>
              </Link>
            </Col>
            {notificationMessages.length !== 0 && (
              <Col style={{ paddingRight: '12px', textTransform: 'capitalize', display: 'flex', alignItems: 'center' }}>
                <Badge count={notificationMessages.length}>
                  <TiBell onClick={loadNotification} style={{ cursor: 'pointer' }} size={32} />
                  <Notification
                    visible={viewNotification}
                    messages={notificationMessages}
                    userName={userProfile && userProfile.data && userProfile.data.employeeName}
                  />
                </Badge>
              </Col>
            )}
            {notificationMessages.length === 0 && dataNotification.length !== 0 && (
              <Col style={{ paddingRight: '12px', textTransform: 'capitalize', display: 'flex', alignItems: 'center' }}>
                <Badge count={dataNotification.length}>
                  <TiBell onClick={loadNotification} style={{ cursor: 'pointer' }} size={32} />
                  <NotificationNews
                    visible={viewNotification}
                    messages={dataNotification}
                    userName={userProfile && userProfile.data && userProfile.data.employeeName}
                  />
                </Badge>
              </Col>
            )}
            {notificationMessages.length === 0 && dataNotification.length == 0 && (
              <Col
                style={{
                  paddingRight: '12px',
                  textTransform: 'capitalize',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <Badge count={notificationMessages.length}>
                  <TiBell style={{ cursor: 'pointer' }} size={32} />
                </Badge>
              </Col>
            )}
          </section>
        </Row>
      </div>
    </Header>
  )
}

export default React.memo(Headers)
