import axios from 'axios'
import config from 'config'
import getUser from 'utils/userData'
import dispatchAction from 'utils/dispatcher'
import { encode } from 'utils/queryString'
import { toast } from 'react-toastify'
// action type strings should be unique across reducers so namespace them with the reducer name
export const actionTypes = {
  NET_TRADING_RESULT_ACCOUNT_SUCCESS: 'NET_TRADING_RESULT_ACCOUNT_SUCCESS',
  NET_TRADING_RESULT_DOWNLOAD: 'NET_TRADING_RESULT_DOWNLOAD',
  NET_TRADING_RESULT_ACCOUNT: 'NET_TRADING_RESULT_ACCOUNT',
  LIST_NET_TRADING_RESULT_ACCOUNT_SUCCESS: 'LIST_NET_TRADING_RESULT_ACCOUNT_SUCCESS',
  LIST_NET_TRADING_RESULT_ACCOUNT: 'LIST_NET_TRADING_RESULT_ACCOUNT',
  LIST_NET_TRADING_RESULT_BRANCH_SUCCESS: 'LIST_NET_TRADING_RESULT_BRANCH_SUCCESS',
  LIST_NET_TRADING_RESULT_BRANCH: 'LIST_NET_TRADING_RESULT_BRANCH',
  LIST_NET_TRADING_RESULT_ACCOUNT_TYPE_SUCCESS: 'LIST_NET_TRADING_RESULT_ACCOUNT_TYPE_SUCCESS',
  LIST_NET_TRADING_RESULT_ACCOUNT_TYPE: 'LIST_NET_TRADING_RESULT_ACCOUNT_TYPE',
  NET_TRADING_RESULT_TIME_SUCCESS: 'NET_TRADING_RESULT_ACCOUNT_SUCCESS',
  NET_TRADING_RESULT_TIME: 'NET_TRADING_RESULT_ACCOUNT',
  LIST_NET_TRADING_RESULT_ACCOUNTING_ACCOUNT_SUCCESS: 'LIST_NET_TRADING_RESULT_ACCOUNTING_ACCOUNT_SUCCESS',
  LIST_NET_TRADING_RESULT_ACCOUNTING_ACCOUNT: 'LIST_NET_TRADING_RESULT_ACCOUNTING_ACCOUNT',
  LIST_NET_TRADING_RESULT_ACCOUNTING_BRANCH_SUCCESS: 'LIST_NET_TRADING_RESULT_ACCOUNTING_BRANCH_SUCCESS',
  LIST_NET_TRADING_RESULT_ACCOUNTING_BRANCH: 'LIST_NET_TRADING_RESULT_ACCOUNTING_BRANCH',
  LIST_NET_TRADING_RESULT_ACCOUNTING_DEAL_NUMBER_SUCCESS: 'LIST_NET_TRADING_RESULT_ACCOUNTING_DEAL_NUMBER_SUCCESS',
  LIST_NET_TRADING_RESULT_ACCOUNTING_DEAL_NUMBER: 'LIST_NET_TRADING_RESULT_ACCOUNTING_DEAL_NUMBER',
  NET_TRADING_RESULT_DOWNLOAD_SUCCESS: 'NET_TRADING_RESULT_DOWNLOAD_SUCCESS'
}

const moduleName = 'Net trading Result'
const path = '/api/net-trading-results'
const exportPath = '/api/net-trading-results-accounting-tab2-account-export'

// actions are where most of the business logic takes place
// they are dispatched by views or by other actions

export const resetListAccount = dispatch => {
  dispatch({
    type: actionTypes.LIST_NET_TRADING_RESULT_ACCOUNT_SUCCESS,
    reset: true,
    data: null
  })
}
export const resetDownloadSuccess = dispatch => {
  dispatch({
    type: actionTypes.RESET_NET_TRADING_RESULT_DOWNLOAD_SUCCESS,
    reset: true,
    data: null
  })
}
export const resetListBranch = dispatch => {
  dispatch({
    type: actionTypes.LIST_NET_TRADING_RESULT_BRANCH_SUCCESS,
    reset: true,
    data: null
  })
}
export const resetListAccountType = dispatch => {
  dispatch({
    type: actionTypes.LIST_NET_TRADING_RESULT_ACCOUNT_TYPE_SUCCESS,
    reset: true,
    data: null
  })
}

export const updateTimeNtr = (dispatch, payload) => {
  const url = config.baseUrl + `/api/net-trading-results/schedulertime`
  const action = async () => {
    const response = await axios.post(url, payload, {
      timeout: config.fetchingTimeout,
      headers: { Authorization: getUser().token }
    })
    if (response.status <= 301) {
      let { data } = response
      dispatch({
        type: actionTypes.NET_TRADING_RESULT_TIME_SUCCESS,
        data
      })
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  dispatchAction(dispatch, actionTypes.NET_TRADING_RESULT_TIME, action)
}

export const getTimeNtr = dispatch => {
  const url = config.baseUrl + `/api/net-trading-results/findSchedulerTime`
  const action = async () => {
    const response = await axios.get(url, {
      timeout: config.fetchingTimeout,
      headers: { Authorization: getUser().token }
    })
    if (response.status <= 301) {
      let { data } = response
      dispatch({
        type: actionTypes.NET_TRADING_RESULT_TIME_SUCCESS,
        data
      })
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  dispatchAction(dispatch, actionTypes.NET_TRADING_RESULT_TIME, action)
}

export const listBranch = (dispatch, params) => {
  const url = config.baseUrl + path + `-tab1-branch?${encode(params)}`
  const action = async () => {
    const response = await axios.get(url, {
      timeout: config.fetchingTimeout,
      headers: { Authorization: getUser().token }
    })
    if (response.status <= 301) {
      let { data, headers } = response
      const count = headers['x-total-count'] || 0
      dispatch({
        type: actionTypes.LIST_NET_TRADING_RESULT_BRANCH_SUCCESS,
        data,
        count
      })
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  dispatchAction(dispatch, actionTypes.LIST_NET_TRADING_RESULT_BRANCH, action)
}
export const getFileExcel = (dispatch, params) => {
  const url =
    config.baseUrl +
    exportPath +
    `?endDate=${params.endDate}` +
    `&floating=${params.floating !== undefined ? params.floating : 0}` +
    `&startDate=${params.startDate}`
  const action = async () => {
    const response = await axios.get(url, {
      timeout: config.fetchingTimeout,
      headers: { Authorization: getUser().token },
      responseType: 'blob'
    })
    if (response.status <= 301) {
      let { data, headers } = response
      // const url = window.URL.createObjectURL(new Blob([response.data]))
      const filename = `NTR_Account_${params.startDate + '_' + params.endDate}` + '.xlsx'
      // console.log('file', filename)
      // let headerLine = response.data.headers['content-disposition']
      // let startFileNameIndex = headerLine.indexOf('"') + 1
      // let endFileNameIndex = headerLine.lastIndexOf('"')
      // let filename = headerLine.substring(startFileNameIndex, endFileNameIndex)
      // console.log('filename', filename)
      // const link = document.createElement('a')
      // link.href = url
      // link.setAttribute('download', `test.xlsx`, 'target', '_blank') //or any other extension
      // document.body.appendChild(link)
      // link.click()
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      })
      const aEle = document.createElement('a') // Create a label
      const href = window.URL.createObjectURL(blob) // Create downloaded link
      aEle.href = href
      aEle.download = filename // File name after download
      document.body.appendChild(aEle)
      aEle.click() // Click to download
      document.body.removeChild(aEle) // Download complete remove element
      window.URL.revokeObjectURL(href) // Release blob object
      dispatch({
        type: actionTypes.NET_TRADING_RESULT_DOWNLOAD_SUCCESS,
        data
      })
      toast.success(`Download File Succes`)
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  dispatchAction(dispatch, actionTypes.NET_TRADING_RESULT_DOWNLOAD, action)
}

export const listAccount = (dispatch, params) => {
  const url = config.baseUrl + path + `-tab3-account?${encode(params)}`
  const action = async () => {
    const response = await axios.get(url, {
      timeout: config.fetchingTimeout,
      headers: { Authorization: getUser().token }
    })
    if (response.status <= 301) {
      let { data, headers } = response
      const count = headers['x-total-count'] || 0
      dispatch({
        type: actionTypes.LIST_NET_TRADING_RESULT_ACCOUNT_SUCCESS,
        data,
        count
      })
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  dispatchAction(dispatch, actionTypes.LIST_NET_TRADING_RESULT_ACCOUNT, action)
}

export const listAccountType = (dispatch, params) => {
  const url = config.baseUrl + path + `-tab2-accountType?${encode(params)}`
  const action = async () => {
    const response = await axios.get(url, {
      timeout: config.fetchingTimeout,
      headers: { Authorization: getUser().token }
    })
    if (response.status <= 301) {
      let { data, headers } = response
      const count = headers['x-total-count'] || 0
      dispatch({
        type: actionTypes.LIST_NET_TRADING_RESULT_ACCOUNT_TYPE_SUCCESS,
        data,
        count
      })
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  dispatchAction(dispatch, actionTypes.LIST_NET_TRADING_RESULT_ACCOUNT_TYPE, action)
}

/**
 *
 * @param {function} dispatch
 * @param {object} params
 */
export const remove = (dispatch, id) => {
  const url = config.baseUrl + path + `/${id}`
  const action = async () => {
    const response = await axios.delete(url, {
      timeout: config.fetchingTimeout,
      headers: { Authorization: getUser().token }
    })
    if (response.status <= 301) {
      let data = response.data
      dispatch({
        type: actionTypes.NET_TRADING_RESULT_ACCOUNT_SUCCESS,
        data
      })
      toast.success(`${moduleName} has been deleted`)
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  dispatchAction(dispatch, actionTypes.NET_TRADING_RESULT_ACCOUNT, action)
}

export const view = (dispatch, id) => {
  const url = config.baseUrl + path + `/${id}`
  const action = async () => {
    const response = await axios.get(url, {
      timeout: config.fetchingTimeout,
      headers: { Authorization: getUser().token }
    })
    if (response.status <= 301) {
      let data = response.data
      dispatch({
        type: actionTypes.NET_TRADING_RESULT_ACCOUNT_SUCCESS,
        data
      })
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  dispatchAction(dispatch, actionTypes.NET_TRADING_RESULT_ACCOUNT, action)
}

// NTR Accounting

export const resetListAccountingAccount = dispatch => {
  dispatch({
    type: actionTypes.LIST_NET_TRADING_RESULT_ACCOUNTING_ACCOUNT_SUCCESS,
    reset: true,
    data: null
  })
}
export const resetListAccountingBranch = dispatch => {
  dispatch({
    type: actionTypes.LIST_NET_TRADING_RESULT_ACCOUNTING_BRANCH_SUCCESS,
    reset: true,
    data: null
  })
}
export const resetListAccountingDealNumber = dispatch => {
  dispatch({
    type: actionTypes.LIST_NET_TRADING_RESULT_ACCOUNTING_DEAL_NUMBER_SUCCESS,
    reset: true,
    data: null
  })
}

export const listAccountingBranch = (dispatch, params) => {
  const url = config.baseUrl + path + `-accounting-tab1-branch?${encode(params)}`
  const action = async () => {
    const response = await axios.get(url, {
      timeout: config.fetchingTimeout,
      headers: { Authorization: getUser().token }
    })
    if (response.status <= 301) {
      let { data, headers } = response
      const count = headers['x-total-count'] || 0
      dispatch({
        type: actionTypes.LIST_NET_TRADING_RESULT_ACCOUNTING_BRANCH_SUCCESS,
        data,
        count
      })
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  dispatchAction(dispatch, actionTypes.LIST_NET_TRADING_RESULT_ACCOUNTING_BRANCH, action)
}

export const listAccountingAccount = (dispatch, params) => {
  const url = config.baseUrl + path + `-accounting-tab2-account?${encode(params)}`
  const action = async () => {
    const response = await axios.get(url, {
      timeout: config.fetchingTimeout,
      headers: { Authorization: getUser().token }
    })
    if (response.status <= 301) {
      let { data, headers } = response
      const count = headers['x-total-count'] || 0
      dispatch({
        type: actionTypes.LIST_NET_TRADING_RESULT_ACCOUNTING_ACCOUNT_SUCCESS,
        data,
        count
      })
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  dispatchAction(dispatch, actionTypes.LIST_NET_TRADING_RESULT_ACCOUNTING_ACCOUNT, action)
}

export const listAccountingDealNumber = (dispatch, params) => {
  const url = config.baseUrl + path + `-accounting-tab3-dealnumber?${encode(params)}`
  const action = async () => {
    const response = await axios.get(url, {
      timeout: config.fetchingTimeout,
      headers: { Authorization: getUser().token }
    })
    if (response.status <= 301) {
      let { data, headers } = response
      const count = headers['x-total-count'] || 0
      dispatch({
        type: actionTypes.LIST_NET_TRADING_RESULT_ACCOUNTING_DEAL_NUMBER_SUCCESS,
        data,
        count
      })
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  dispatchAction(dispatch, actionTypes.LIST_NET_TRADING_RESULT_ACCOUNTING_DEAL_NUMBER, action)
}
