import axios from 'axios'
import config from 'config'
import getUser from 'utils/userData'
import dispatchAction from 'utils/dispatcher'
import { encode } from 'utils/queryString'
import { toast } from 'react-toastify'
// action type strings should be unique across reducers so namespace them with the reducer name
export const actionTypes = {
  USER_SUCCESS: 'USER_SUCCESS',
  USER: 'USER',
  USER_LOCK_SUCCESS: 'USER_LOCK_SUCCESS',
  USER_LOCK: 'USER_LOCK',
  LIST_USER_SUCCESS: 'LIST_USER_SUCCESS',
  LIST_USER: 'LIST_USER',
  LIST_USER_MASTER_SUCCESS: 'LIST_USER_MASTER_SUCCESS',
  LIST_USER_MASTER: 'LIST_USER_MASTER'
}

const moduleName = 'User'
const path = '/api/users'

// actions are where most of the business logic takes place
// they are dispatched by views or by other actions
/**
 *
 * @param {function} dispatch
 * @param {object} payload
 */
export const create = async (dispatch, payload, params) => {
  const user = getUser()
  const url = config.baseUrl + path + `?${encode(params)}`
  const action = async () => {
    const response = await axios.post(url, payload, {
      timeout: config.fetchingTimeout,
      headers: { Authorization: user.token }
    })
    if (response.status <= 301) {
      let { data } = response
      dispatch({
        type: actionTypes.USER_SUCCESS,
        data
      })
      toast.success(`${moduleName} has been created, please check the registered email`)
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  await dispatchAction(dispatch, actionTypes.USER, action)
}

/**
 *
 * @param {function} dispatch
 * @param {object} payload
 */
export const update = async (dispatch, payload) => {
  const user = getUser()
  const url = config.baseUrl + path
  const action = async () => {
    const response = await axios.put(url, payload, {
      timeout: config.fetchingTimeout,
      headers: { Authorization: user.token }
    })
    if (response.status <= 301) {
      let { data } = response
      dispatch({
        type: actionTypes.USER_SUCCESS,
        data
      })
      toast.success(`${moduleName} has been updated`)
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  await dispatchAction(dispatch, actionTypes.USER, action)
}

/**
 *
 * @param {function} dispatch
 * @param {object} params
 */
export const list = (dispatch, params) => {
  const page = params.page
  const url = config.baseUrl + '/api/users/search' + `?${encode(params)}`
  const action = async () => {
    const response = await axios.get(url, {
      timeout: config.fetchingTimeout,
      headers: { Authorization: getUser().token }
    })
    if (response.status <= 301) {
      let { data, headers } = response
      const count = headers['x-total-count'] || 0
      dispatch({
        type: actionTypes.LIST_USER_SUCCESS,
        data,
        count,
        paging: { [page]: data }
      })
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  dispatchAction(dispatch, actionTypes.LIST_USER, action)
}

export const listMaster = (dispatch, params) => {
  const url = config.baseUrl + '/api/users/search' + `?${encode(params)}`
  const action = async () => {
    const response = await axios.get(url, {
      timeout: config.fetchingTimeout,
      headers: { Authorization: getUser().token }
    })
    if (response.status <= 301) {
      let { data, headers } = response
      const count = headers['x-total-count'] || 0
      dispatch({
        type: actionTypes.LIST_USER_MASTER_SUCCESS,
        data,
        count,
        paging: {}
      })
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  dispatchAction(dispatch, actionTypes.LIST_USER_MASTER, action)
}

export const resetList = dispatch => {
  dispatch({
    type: actionTypes.LIST_USER_SUCCESS,
    reset: true
  })
}

/**
 *
 * @param {function} dispatch
 * @param {object} params
 */
export const remove = (dispatch, id) => {
  const url = config.baseUrl + path + `/${id}`
  const action = async () => {
    const response = await axios.delete(url, {
      timeout: config.fetchingTimeout,
      headers: { Authorization: getUser().token }
    })
    if (response.status <= 301) {
      let data = response.data
      dispatch({
        type: actionTypes.USER_SUCCESS,
        data
      })
      toast.success(`${moduleName} has been deleted`)
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  dispatchAction(dispatch, actionTypes.USER, action)
}

export const view = (dispatch, id) => {
  const url = config.baseUrl + path + `/${id}`
  const action = async () => {
    const response = await axios.get(url, {
      timeout: config.fetchingTimeout,
      headers: { Authorization: getUser().token }
    })
    if (response.status <= 301) {
      let data = response.data
      dispatch({
        type: actionTypes.USER_SUCCESS,
        data
      })
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  dispatchAction(dispatch, actionTypes.USER, action)
}
export const userLock = async (dispatch, payload) => {
  const user = getUser()
  const url = config.baseUrl + path + '/unlock-lock'
  const action = async () => {
    const response = await axios.put(url, payload, {
      timeout: config.fetchingTimeout,
      headers: { Authorization: user.token }
    })
    if (response.status <= 301) {
      let { data } = response
      dispatch({
        type: actionTypes.USER_LOCK_SUCCESS,
        data
      })
      const status = payload.jhiLocked ? 'locked' : 'unlocked'
      toast.success(`${moduleName} has been ${status}`)
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  await dispatchAction(dispatch, actionTypes.USER_LOCK, action)
}
