import axios from 'axios'
import config from 'config'
import getUser from 'utils/userData'
import dispatchAction from 'utils/dispatcher'
import { encode } from 'utils/queryString'
import { toast } from 'react-toastify'
// action type strings should be unique across reducers so namespace them with the reducer name
export const actionTypes = {
  PROSPECT_SUCCESS: 'PROSPECT_SUCCESS',
  PROSPECT: 'PROSPECT',
  LIST_PROSPECT_SUCCESS: 'LIST_PROSPECT_SUCCESS',
  LIST_PROSPECT: 'LIST_PROSPECT',
  LIST_PROSPECT_HISTORY_SUCCESS: 'LIST_PROSPECT_HISTORY_SUCCESS',
  LIST_PROSPECT_HISTORY: 'LIST_PROSPECT_HISTORY',
  LIST_PROSPECT_NOTIFICATION_SUCCESS: 'LIST_PROSPECT_NOTIFICATION_SUCCESS',
  LIST_PROSPECT_NOTIFICATION: 'LIST_PROSPECT_NOTIFICATION',
  LIST_PROSPECT_VISUALIZATION_SUCCESS: 'LIST_PROSPECT_VISUALIZATION_SUCCESS',
  LIST_PROSPECT_VISUALIZATION: 'LIST_PROSPECT_VISUALIZATION',
  LIST_PROSPECT_VISUALIZATION_BRANCH_SUCCESS: 'LIST_PROSPECT_VISUALIZATION_BRANCH_SUCCESS',
  LIST_PROSPECT_VISUALIZATION_BRANCH: 'LIST_PROSPECT_VISUALIZATION_BRANCH',
  LIST_PROSPECT_VISUALIZATION_ACCOUNT_SUCCESS: 'LIST_PROSPECT_VISUALIZATION_ACCOUNT_SUCCESS',
  LIST_PROSPECT_VISUALIZATION_ACCOUNT: 'LIST_PROSPECT_VISUALIZATION_ACCOUNT'
}

const moduleName = 'Prospect'
const path = '/api/prospect-ms'

// actions are where most of the business logic takes place
// they are dispatched by views or by other actions
/**
 *
 * @param {function} dispatch
 * @param {object} payload
 */
export const create = async (dispatch, payload, params) => {
  const user = getUser()
  const url = config.baseUrl + path + `?${encode(params)}`
  const action = async () => {
    const response = await axios.post(url, payload, {
      timeout: config.fetchingTimeout,
      headers: { Authorization: user.token }
    })
    if (response.status <= 301) {
      let { data } = response
      dispatch({
        type: actionTypes.PROSPECT_SUCCESS,
        data
      })
      toast.success(`${moduleName} has been saved`)
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  await dispatchAction(dispatch, actionTypes.PROSPECT, action)
}

/**
 *
 * @param {function} dispatch
 * @param {object} payload
 */
export const update = async (dispatch, payload) => {
  const user = getUser()
  const url = config.baseUrl + path
  const action = async () => {
    const response = await axios.put(url, payload, {
      timeout: config.fetchingTimeout,
      headers: { Authorization: user.token }
    })
    if (response.status <= 301) {
      let { data } = response
      dispatch({
        type: actionTypes.PROSPECT_SUCCESS,
        data
      })
      toast.success(`${moduleName} has been updated`)
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  await dispatchAction(dispatch, actionTypes.PROSPECT, action)
}

/**
 *
 * @param {function} dispatch
 * @param {object} params
 */
export const list = (dispatch, params) => {
  const url = config.baseUrl + path + '/search' + `?${encode(params)}`
  const action = async () => {
    const response = await axios.get(url, {
      timeout: config.fetchingTimeout,
      headers: { Authorization: getUser().token }
    })
    if (response.status <= 301) {
      const page = params.page
      let { data, headers } = response
      const count = headers['x-total-count'] || 0
      dispatch({
        type: actionTypes.LIST_PROSPECT_SUCCESS,
        data,
        count,
        paging: { [page]: data },
        page
      })
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  dispatchAction(dispatch, actionTypes.LIST_PROSPECT, action)
}

export const resetList = dispatch => {
  dispatch({
    type: actionTypes.LIST_PROSPECT_SUCCESS,
    reset: true,
    data: null
  })
}

export const listNotification = (dispatch, params) => {
  const url = config.baseUrl + path + '/reminder' + `?${encode(params)}`
  const action = async () => {
    const response = await axios.get(url, {
      timeout: config.fetchingTimeout,
      headers: { Authorization: getUser().token }
    })
    if (response.status <= 301) {
      const page = params.page
      let { data, headers } = response
      const count = headers['x-total-count'] || 0
      dispatch({
        type: actionTypes.LIST_PROSPECT_NOTIFICATION_SUCCESS,
        data,
        count,
        paging: { [page]: data }
      })
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  dispatchAction(dispatch, actionTypes.LIST_PROSPECT_NOTIFICATION, action)
}

export const listHistory = (dispatch, { prospectId, ...params }) => {
  const url = config.baseUrl + path + `/history/${prospectId}` + `?${encode(params)}`
  const action = async () => {
    const response = await axios.get(url, {
      timeout: config.fetchingTimeout,
      headers: { Authorization: getUser().token }
    })
    if (response.status <= 301) {
      const page = params.page
      let { data, headers } = response
      const count = headers['x-total-count'] || 0
      dispatch({
        type: actionTypes.LIST_PROSPECT_HISTORY_SUCCESS,
        data,
        count,
        paging: { [page]: data }
      })
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  dispatchAction(dispatch, actionTypes.LIST_PROSPECT_HISTORY, action)
}

/**
 *
 * @param {function} dispatch
 * @param {object} params
 */
export const remove = (dispatch, id) => {
  const url = config.baseUrl + path + `/${id}`
  const action = async () => {
    const response = await axios.delete(url, {
      timeout: config.fetchingTimeout,
      headers: { Authorization: getUser().token }
    })
    if (response.status <= 301) {
      let data = response.data
      dispatch({
        type: actionTypes.PROSPECT_SUCCESS,
        data
      })
      toast.success(`${moduleName} has been deleted`)
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  dispatchAction(dispatch, actionTypes.PROSPECT, action)
}

export const view = (dispatch, id) => {
  const url = config.baseUrl + path + `/${id}`
  const action = async () => {
    const response = await axios.get(url, {
      timeout: config.fetchingTimeout,
      headers: { Authorization: getUser().token }
    })
    if (response.status <= 301) {
      let data = response.data
      dispatch({
        type: actionTypes.PROSPECT_SUCCESS,
        data
      })
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  dispatchAction(dispatch, actionTypes.PROSPECT, action)
}

export const listVisualization = (dispatch, params) => {
  const url = config.baseUrl + '/api/prospect-visualization-rate/' + `?${encode(params)}`
  const action = async () => {
    const response = await axios.get(url, {
      timeout: config.fetchingTimeout,
      headers: { Authorization: getUser().token }
    })
    if (response.status <= 301) {
      const page = params.page
      let { data, headers } = response
      const count = headers['x-total-count'] || 0
      dispatch({
        type: actionTypes.LIST_PROSPECT_VISUALIZATION_SUCCESS,
        data,
        count,
        paging: { [page]: data }
      })
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  dispatchAction(dispatch, actionTypes.LIST_PROSPECT_VISUALIZATION, action)
}

export const resetListVisualization = dispatch => {
  dispatch({
    type: actionTypes.LIST_PROSPECT_VISUALIZATION_SUCCESS,
    reset: true,
    data: null
  })
}

export const listVisualizationBranch = (dispatch, params, customApi) => {
  let url = config.baseUrl + `/api/prospect-visualization-rate/branchOfficeId?${encode(params)}`
  if (customApi) {
    url = `${config.baseUrl}${customApi}?${encode(params)}`
  }
  const action = async () => {
    const response = await axios.get(url, {
      timeout: config.fetchingTimeout,
      headers: { Authorization: getUser().token }
    })
    if (response.status <= 301) {
      const page = params.page
      let { data, headers } = response
      const count = headers['x-total-count'] || 0
      dispatch({
        type: actionTypes.LIST_PROSPECT_VISUALIZATION_BRANCH_SUCCESS,
        data,
        count,
        paging: { [page]: data }
      })
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  dispatchAction(dispatch, actionTypes.LIST_PROSPECT_VISUALIZATION_BRANCH, action)
}

export const resetListVisualizationBranch = dispatch => {
  dispatch({
    type: actionTypes.LIST_PROSPECT_VISUALIZATION_BRANCH_SUCCESS,
    reset: true,
    data: null
  })
}

export const listVisualizationAccount = (dispatch, params) => {
  const url = config.baseUrl + `/api/prospect-visualization-rate-account/branchOfficeId?${encode(params)}`

  const action = async () => {
    const response = await axios.get(url, {
      timeout: config.fetchingTimeout,
      headers: { Authorization: getUser().token }
    })
    if (response.status <= 301) {
      const page = params.page || 0
      let { data, headers } = response
      const count = headers['x-total-count'] || 0
      dispatch({
        type: actionTypes.LIST_PROSPECT_VISUALIZATION_ACCOUNT_SUCCESS,
        data,
        count,
        paging: { [page]: data }
      })
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  dispatchAction(dispatch, actionTypes.LIST_PROSPECT_VISUALIZATION_ACCOUNT, action)
}

export const resetListVisualizationAccount = dispatch => {
  dispatch({
    type: actionTypes.LIST_PROSPECT_VISUALIZATION_ACCOUNT_SUCCESS,
    reset: true,
    data: null
  })
}
