import Cookies from 'js-cookie'
import { clearingCache } from './refreshCache'
export default function Logout(setLogoutState) {
  clearingCache()
  if (window) {
    window.localStorage.removeItem('isLogin')
    window.localStorage.removeItem('userData')
    window.sessionStorage.clear()
    Cookies.remove('user')
  }
  if (setLogoutState) {
    setLogoutState(true)
  } else {
    window.location.replace('/')
  }
}

// if the user not logout properly, clear local storage & cache
export function reLogout() {
  clearingCache(false)
  if (window) {
    window.localStorage.removeItem('isLogin')
    window.localStorage.removeItem('userData')
    window.sessionStorage.clear()
    Cookies.remove('user')
  }
}
