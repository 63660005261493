// const dev = process.env.NODE_ENV === 'development'
const dev = false//process.env.NODE_ENV === 'development'
// const link = "http://localhost:8080"
// const linkWs = "ws://localhost:8080"
const link = 'https://portal-beaf.ati-bv.dev'
const linkWs = 'ws://portal-beaf.ati-bv.dev'

// const link = 'https://portal-beaf.ati-bv.dev'
// const linkWs = 'ws://portal-beaf.ati-bv.dev'
// const link = 'http://10.90.10.145:8080'
// const linkWs = 'ws://10.90.10.145'

// const link = 'http://116.206.196.142:8080'
// const linkWs = 'ws://116.206.196.142'
const appConfig = {
  baseUrl: process.env.BASE_URL || dev ? link : 'https://portal.agrodana-futures.com',
  baseUrlSocket: process.env.BASE_URL_SOCKET || dev ? linkWs : 'wss://portal.agrodana-futures.com',
  fetchingTimeout: 1200000,
  // reCaptchaSiteKey: '6Lf0CMMZAAAAAE1pn8G31-XHHTIASRQ6FQoVd0ts'
  reCaptchaSiteKey: '6LfCCd8ZAAAAAJWxPnL3IppkWsJCL_ygpDBCH--j'
}
export default appConfig
