export const actionTypes = {
  SET_COLLAPSE: 'SET_COLLAPSE'
}

export const setCollapse = (dispatch, state) => {
  dispatch({
    type: actionTypes.SET_COLLAPSE,
    data: state
  })
}
