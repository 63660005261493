import axios from 'axios'
import config from 'config'
import getUser from 'utils/userData'
import dispatchAction from 'utils/dispatcher'
import { encode } from 'utils/queryString'
// action type strings should be unique across reducers so namespace them with the reducer name
export const actionTypes = {
  TAX_ACCUMULATION_BRANCH_SUCCESS: 'TAX_ACCUMULATION_BRANCH_SUCCESS',
  TAX_ACCUMULATION_BRANCH: 'TAX_ACCUMULATION_BRANCH',
  TAX_ACCUMULATION_USER_SUCCESS: 'TAX_ACCUMULATION_USER_SUCCESS',
  TAX_ACCUMULATION_USER: 'TAX_ACCUMULATION_USER',
  TAX_ACCUMULATION_USER_DETAIL_SUCCESS: 'TAX_ACCUMULATION_USER_DETAIL_SUCCESS',
  TAX_ACCUMULATION_USER_DETAIL: 'TAX_ACCUMULATION_USER_DETAIL'
}

// const moduleName = 'Accumulation'
const path = '/api/accumulation-commission'

/**
 *
 * @param {function} dispatch
 * @param {object} params
 */
export const listBranch = (dispatch, params) => {
  const page = params.page
  const url = `${config.baseUrl}${path}-branch?${encode(params)}`
  const action = async () => {
    const response = await axios.get(url, {
      timeout: config.fetchingTimeout,
      headers: { Authorization: getUser().token }
    })
    if (response.status <= 301) {
      let { data, headers } = response
      const count = headers['x-total-count'] || 0
      dispatch({
        type: actionTypes.TAX_ACCUMULATION_BRANCH_SUCCESS,
        data,
        count,
        paging: { [page]: data }
      })
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  dispatchAction(dispatch, actionTypes.TAX_ACCUMULATION_BRANCH, action)
}

export const listUser = (dispatch, params) => {
  const page = params.page
  const url = `${config.baseUrl}${path}-user?${encode(params)}`
  const action = async () => {
    const response = await axios.get(url, {
      timeout: config.fetchingTimeout,
      headers: { Authorization: getUser().token }
    })
    if (response.status <= 301) {
      let { data, headers } = response
      const count = headers['x-total-count'] || 0
      dispatch({
        type: actionTypes.TAX_ACCUMULATION_USER_SUCCESS,
        data,
        count,
        paging: { [page]: data }
      })
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  dispatchAction(dispatch, actionTypes.TAX_ACCUMULATION_USER, action)
}

export const listUserDetail = (dispatch, params) => {
  const page = params.page
  const url = `${config.baseUrl}${path}-userdetail?${encode(params)}`
  const action = async () => {
    const response = await axios.get(url, {
      timeout: config.fetchingTimeout,
      headers: { Authorization: getUser().token }
    })
    if (response.status <= 301) {
      let { data, headers } = response
      const count = headers['x-total-count'] || 0
      dispatch({
        type: actionTypes.TAX_ACCUMULATION_USER_DETAIL_SUCCESS,
        data,
        count,
        paging: { [page]: data }
      })
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  dispatchAction(dispatch, actionTypes.TAX_ACCUMULATION_USER_DETAIL, action)
}

export const resetListBranch = dispatch => {
  dispatch({
    type: actionTypes.TAX_ACCUMULATION_BRANCH_SUCCESS,
    reset: true,
    data: null
  })
}

export const resetListUser = dispatch => {
  dispatch({
    type: actionTypes.TAX_ACCUMULATION_USER_SUCCESS,
    reset: true,
    data: null
  })
}

export const resetListUserDetail = dispatch => {
  dispatch({
    type: actionTypes.TAX_ACCUMULATION_USER_DETAIL_SUCCESS,
    reset: true,
    data: null
  })
}
