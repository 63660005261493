const getUser = short => {
  const userData = getCookie('user')
  const data = userData ? JSON.parse(userData) : null
  const isLogin = (window && JSON.parse(localStorage.getItem('isLogin'))) || false
  const localStorageUser = JSON.parse(localStorage.getItem('user'))
  const { sub, role, fullname, employeeid } = data || {}
  return data && short ? { sub, role, fullname, employeeid, isLogin } : data || localStorageUser
}

const isLogin = () => {
  const datalogin = (window && JSON.parse(localStorage.getItem('isLogin'))) || false
  const isLogin = !!(datalogin && getUser())
  return isLogin
}

const isMe = id => {
  const user = getUser()
  return user && user.employeeId == id
}

const getUserRoutes = () => {
  const userData = JSON.parse(localStorage.getItem('userData'))
  let routes = []
  if (userData && userData.menuAccess) {
    routes = userData.menuAccess.map(menu => menu.modulePath)
  }
  return routes
}

const getMenuAccess = getAll => {
  const userData = JSON.parse(localStorage.getItem('userData'))
  let menuPropery = []
  if (userData && userData.menuAccess) {
    menuPropery = !getAll
      ? userData.menuAccess.map(data => ({
          moduleName: data.moduleName,
          moduleIcon: data.moduleIcon,
          modulePath: data.modulePath
        }))
      : userData.menuAccess
  }
  return menuPropery
}

const getUserPosition = () => {
  const userData = JSON.parse(localStorage.getItem('userData'))
  return userData.positionMs
}

const isSecretary = () => {
  return getUserPosition().positionCode === 'SKR'
}

function getCookie(cname) {
  const name = cname + '='
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

export default getUser

export { isLogin, isSecretary, getUserRoutes, getMenuAccess, isMe }
