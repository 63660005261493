import axios from 'axios'
import config from 'config'
import getUser from 'utils/userData'
import dispatchAction from 'utils/dispatcher'
import { encode } from 'utils/queryString'
import { toast } from 'react-toastify'
// action type strings should be unique across reducers so namespace them with the reducer name
export const actionTypes = {
  GENERATE_NTR_SUCCESS: 'GENERATE_NTR_SUCCESS',
  BROADCAST_GENERATE_NTR: 'BROADCAST_GENERATE_NTR',
  PREVIEW_DATA_NTR: 'PREVIEW_DATA_NTR',
  PREVIEW_DATA_NTR_SUCCESS: 'PREVIEW_DATA_NTR_SUCCESS'
}

const moduleName = 'Generate NTR Success'
const path = '/api/test-whatsapp'

// actions are where most of the business logic takes place
// they are dispatched by views or by other actions
/**
 *
 * @param {function} dispatch
 * @param {object} payload
 */
export const generateNtr = (dispatch, payload) => {
  const user = getUser()
  const sendByWhatsapp = config.baseUrl + '/api/tes-whatsapp'
  const sendByEmail = config.baseUrl + '/api/send-ntr-email'
  const sendBySms = config.baseUrl + `/api/ntr-sms?phone=${payload.phone}`
  const smsPayload = {
    date: payload.date,
    principal: payload.principal,
    principalList: payload.principalList
  }
  const action = async () => {
    const response = await axios.post(
      payload.email ? sendByEmail : payload.phone ? sendBySms : sendByWhatsapp,
      payload.phone ? smsPayload : payload,
      {
        timeout: config.fetchingTimeout,
        headers: { Authorization: user.token }
      }
    )
    if (response.status <= 301) {
      let { data } = response
      dispatch({
        type: actionTypes.GENERATE_NTR_SUCCESS
      })
      toast.success(`${moduleName} has been saved`)
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  dispatchAction(dispatch, actionTypes.BROADCAST_GENERATE_NTR, action)
}
export const generatePreviewNtr = (dispatch, payload) => {
  const user = getUser()
  const preview = config.baseUrl + '/api/ntr-preview'
  const action = async () => {
    const response = await axios.post(preview, payload, {
      timeout: config.fetchingTimeout,
      headers: { Authorization: user.token }
    })
    if (response.status <= 301) {
      let { data } = response
      dispatch({
        type: actionTypes.PREVIEW_DATA_NTR_SUCCESS,
        data
      })
      // toast.success(`${moduleName} has been saved`)
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  dispatchAction(dispatch, actionTypes.PREVIEW_DATA_NTR, action)
}
