import React from 'react'
import { node, bool, func, string } from 'prop-types'
import { Menu, Drawer } from 'antd'
import { Redirect, Link } from 'react-router-dom'
import logout from 'utils/logout'
import { getMenuAccess } from 'utils/userData'
import { handleListModule } from 'utils'
import { usePrevious, useStateValue } from 'context'
import * as fontAwesome from 'react-icons/ti'

const { SubMenu } = Menu

function getSubMenuKey(arr) {
  let keys = []
  for (let i = 0; i < arr.length; i++) {
    const menu = arr[i]
    const key = menu.modulePath
    if (menu.submenu) {
      keys.push(key)
    }
  }
  return keys
}

function MobileMenu({ collapsed, handleCollapse }) {
  const logoutMenu = {
    moduleName: 'Logout',
    moduleIcon: 'TiArrowBack',
    onClick: () => logout(setLogouts)
  }
  const Icons = ({ children }) => <i className="menu-icon">{children}</i>
  Icons.propTypes = { children: node }

  const IconGenerator = ({ iconName, ...rest }) => {
    const IconGenerated = fontAwesome[iconName || 'TiThLarge']
    return <IconGenerated {...rest} color="#fff" />
  }
  IconGenerator.propTypes = {
    iconName: string
  }

  const [openSubMenu, setOpenSubMenu] = React.useState([])
  const [keyMenu, setKeyMenu] = React.useState([])
  const [userMenu, setUserMenu] = React.useState(null)
  const [logouts, setLogouts] = React.useState(false)
  const rootSubmenuKeys = getSubMenuKey(userMenu || [])

  const [userProfile] = useStateValue('userProfile')

  const onOpenChange = openKeys => {
    const latestOpenKey = openKeys.find(key => openSubMenu.indexOf(key) === -1)
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenSubMenu(openKeys)
    } else {
      setOpenSubMenu(latestOpenKey ? [latestOpenKey] : [])
    }
  }

  const jsonString = obj => JSON.stringify(obj)
  const modules = jsonString(getMenuAccess(true))
  const prevModules = jsonString(modules)
  const pathname = window ? window.location.hash : '#/'
  const prevPathname = usePrevious(pathname)
  const parseModules = JSON.parse(modules)

  const listMenuMemoized = React.useMemo(() => handleListModule(parseModules, ['/my-profile']), [parseModules])

  React.useEffect(() => {
    if (pathname && prevPathname !== pathname) {
      setKeyMenu([pathname.slice(1)])
    }
    if (!userMenu && userProfile && modules !== prevModules) {
      setUserMenu([...listMenuMemoized, logoutMenu])
    }
  }, [listMenuMemoized, logoutMenu, modules, pathname, prevModules, prevPathname, userMenu, userProfile])

  if (logouts) return <Redirect to="/" />
  return (
    <Drawer
      bodyStyle={{ padding: 0 }}
      title="Menu"
      placement="left"
      onClose={() => handleCollapse(false)}
      visible={collapsed}
      key="left"
    >
      <Menu
        mode="inline"
        openKeys={openSubMenu}
        onOpenChange={onOpenChange}
        defaultSelectedKeys={['/home']}
        selectedKeys={keyMenu}
      >
        {userMenu &&
          userMenu.map((item, idx) => {
            if (item.submenu) {
              return (
                <SubMenu
                  key={item.modulePath || idx}
                  title={
                    <span>
                      {/* <Icons>
                        <IconGenerator size="30px" iconName={item.moduleIcon} />
                      </Icons> */}
                      <span className={collapsed ? 'hide-menu-title' : 'menu-title'}>{item.moduleName}</span>
                    </span>
                  }
                >
                  {item.submenu.map((submenuItem, idx2) => (
                    <Menu.Item key={submenuItem.modulePath || idx + idx2}>
                      <Link to={submenuItem.modulePath}>{submenuItem.moduleName}</Link>
                    </Menu.Item>
                  ))}
                </SubMenu>
              )
            } else {
              return item.onClick ? (
                <Menu.Item className="menu-item" key={item.modulePath || idx} onClick={item.onClick}>
                  {/* <Icons>
                    <IconGenerator size="30px" iconName={item.moduleIcon} />
                  </Icons> */}
                  <span className={collapsed ? 'hide-menu-title' : 'menu-title'}>{item.moduleName}</span>
                </Menu.Item>
              ) : (
                <Menu.Item className="menu-item" key={item.modulePath || idx}>
                  <Link to={item.modulePath}>
                    {/* <Icons>
                      <IconGenerator size="30px" iconName={item.moduleIcon} />
                    </Icons> */}
                    <span className={collapsed ? 'hide-menu-title' : 'menu-title'}>{item.moduleName}</span>
                  </Link>
                </Menu.Item>
              )
            }
          })}
      </Menu>
    </Drawer>
  )
}

MobileMenu.propTypes = {
  collapsed: bool,
  handleCollapse: func
}

MobileMenu.defaultProps = {
  handleCollapse: () => {}
}

export default MobileMenu
