import React from 'react'
import { array, any, object } from 'prop-types'
import { Table } from 'antd'
import './style.css'

// const underscoreToCapitalize = text => {
//   return typeof text === 'string'
//     ? text.split('_').reduce((acc, txt, index, arr) => {
//         return acc + txt.charAt(0).toUpperCase() + txt.slice(1) + (index !== arr.length - 1 ? ' ' : '')
//       }, '')
//     : ''
// }

const camelcaseToCapitalize = text => {
  const separate = text.replace(/([A-Z])/g, ' $1')
  const finalResult = separate.charAt(0).toUpperCase() + separate.slice(1)
  return finalResult
}

function MainTable({ data, excludeColumns, columnProperty, additionalColumn, ...rest }) {
  const getColumn = arr => {
    if (arr[0]) {
      const conditions = columnProperty
      const titles = Object.keys(arr[0]).filter(title => excludeColumns.includes(title) === false)
      return titles.map(title => {
        let column = {
          title: camelcaseToCapitalize(title),
          key: title,
          dataIndex: title
        }
        for (let i = 0; i < conditions.length; i++) {
          let condition = conditions[i]
          if (condition.dataIndex === title) {
            column = {
              ...column,
              ...condition
            }
          }
        }
        return column
      })
    }
  }

  let generatedColumn = getColumn(data)
  const columnLocation = generatedColumn && generatedColumn.map(col => col.location).filter(loc => loc !== undefined)
  if (columnLocation && columnLocation.length === generatedColumn.length) {
    let newArrays = []
    for (let i = 0; i < columnLocation.length; i++) {
      newArrays[i] = generatedColumn.filter(data => data.location === i)[0]
    }
    generatedColumn = newArrays
  }
  const columns = generatedColumn ? [...generatedColumn, ...additionalColumn] : []
  return (
    <div className="root-table">
      <Table rowKey={(_, index) => 'key__' + index} columns={columns} dataSource={data} {...rest} />
    </div>
  )
}

MainTable.propTypes = {
  data: array,
  excludeColumns: array,
  columnProperty: array.isRequired,
  additionalColumn: array,
  title: any
}

MainTable.defaultProps = {
  data: [],
  title: 'Result',
  excludeColumns: [],
  additionalColumn: []
}

export default MainTable

export const DynamicTableConfig = ({ configs = [], ...rest }) => <MainTable {...rest} {...configs} />

DynamicTableConfig.propTypes = {
  configs: object
}
