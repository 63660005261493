import axios from 'axios'
import config from 'config'
import getUser from 'utils/userData'
import dispatchAction from 'utils/dispatcher'
import { toast } from 'react-toastify'
import { encode } from 'utils/queryString'
// action type strings should be unique across reducers so namespace them with the reducer name
export const actionTypes = {
  ORGANIZATION_STRUCTURE: 'ORGANIZATION_STRUCTURE',
  ORGANIZATION_STRUCTURE_SUCCESS: 'ORGANIZATION_STRUCTURE_SUCCESS',
  LIST_ORGANIZATION_STRUCTURE: 'LIST_ORGANIZATION_STRUCTURE',
  LIST_ORGANIZATION_STRUCTURE_SUCCESS: 'LIST_ORGANIZATION_STRUCTURE_SUCCESS'
}

const moduleName = 'Organization structure'
const path = '/api/users/organization-structure'

// actions are where most of the business logic takes place
// they are dispatched by views or by other actions
/**
 *
 * @param {function} dispatch
 * @param {object} payload
 */
export const update = async (dispatch, payload) => {
  const user = getUser()
  const url = config.baseUrl + path
  const { employeeForm, employeeId } = payload
  const action = async () => {
    const response = await axios.put(
      url,
      { employeeForm, employeeId },
      {
        timeout: config.fetchingTimeout,
        headers: { Authorization: user.token }
      }
    )
    if (response.status <= 301) {
      let { data } = response
      dispatch({
        type: actionTypes.ORGANIZATION_STRUCTURE_SUCCESS,
        data: data || payload
      })
      toast.success(`${moduleName} has been updated`)
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  await dispatchAction(dispatch, actionTypes.ORGANIZATION_STRUCTURE, action)
}

export const view = (dispatch, id) => {
  const url = `${config.baseUrl}${path}/${id}`
  const action = async () => {
    const response = await axios.get(url, {
      timeout: config.fetchingTimeout,
      headers: { Authorization: getUser().token }
    })
    if (response.status <= 301) {
      let data = response.data
      dispatch({
        type: actionTypes.ORGANIZATION_STRUCTURE_SUCCESS,
        data
      })
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  dispatchAction(dispatch, actionTypes.ORGANIZATION_STRUCTURE, action)
}

/**
 *
 * @param {function} dispatch
 * @param {object} params
 */
export const list = (dispatch, params) => {
  const key = Object.keys(params)
    .map(k => params[k])
    .join('.')
  const url = config.baseUrl + path + '/list' + `?${encode(params)}`
  const action = async () => {
    const response = await axios.get(url, {
      timeout: config.fetchingTimeout,
      headers: { Authorization: getUser().token }
    })
    if (response.status <= 301) {
      let { data, headers } = response
      const count = headers['x-total-count'] || 0
      dispatch({
        type: actionTypes.LIST_ORGANIZATION_STRUCTURE_SUCCESS,
        data,
        count,
        lastRequest: key,
        paging: { [key]: data }
      })
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  dispatchAction(dispatch, actionTypes.LIST_ORGANIZATION_STRUCTURE, action)
}

export const resetList = dispatch => {
  dispatch({
    type: actionTypes.LIST_ORGANIZATION_STRUCTURE_SUCCESS,
    reset: true
  })
}

export const resetOrg = dispatch => {
  dispatch({
    type: actionTypes.ORGANIZATION_STRUCTURE_SUCCESS,
    reset: true
  })
}

export const getMainLead = dispatch => {
  const url = `${config.baseUrl}${path}/getPresidenDirector`
  const action = async () => {
    const response = await axios.get(url, {
      timeout: config.fetchingTimeout,
      headers: { Authorization: getUser().token }
    })
    if (response.status <= 301) {
      let data = response.data
      dispatch({
        type: actionTypes.ORGANIZATION_STRUCTURE_SUCCESS,
        data
      })
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  dispatchAction(dispatch, actionTypes.ORGANIZATION_STRUCTURE, action)
}

export const fetchChild = async params => {
  try {
    const url = config.baseUrl + path + '/list' + `?${encode(params)}`
    const response = await axios.get(url, {
      timeout: config.fetchingTimeout,
      headers: { Authorization: getUser().token }
    })
    return response.data
  } catch (error) {
    console.log(error)
  }
}
