import React from 'react'
import ReactDOM from 'react-dom'
import { ToastContainer, toast } from 'react-toastify'
import reducers from 'context/reducers'
import config from 'config'
import { loadReCaptcha } from 'react-recaptcha-v3'
import refreshCache, { clearingCache } from 'utils/refreshCache'
import EnvInfo from 'components/env-ui/envInfo'
import { Provider as ContextProvider, AuthProvider, SocketProvider, ScreenBreakpointProvider } from 'context'
import App from './router'
import * as serviceWorker from './serviceWorker'
import Nprogress from 'nprogress'
import './i18n'
import './global.css'
import 'react-toastify/dist/ReactToastify.css'

Nprogress.configure({ showSpinner: false, easing: 'ease', speed: 200 })
const dev = process.env.NODE_ENV === 'development'
const EnvironmentLabel = dev ? <EnvInfo /> : ''
const sw = () => (dev ? serviceWorker.unregister() : serviceWorker.register())
const Root = () => {
  /**
   * check if there is new update deployed, if any then clear caches
   */
  React.useEffect(() => {
    refreshCache().then(newUpdate => {
      newUpdate && clearingCache()
    })
  })
  React.useEffect(() => {
    loadReCaptcha(config.reCaptchaSiteKey)
  }, [])
  return (
    <ScreenBreakpointProvider minWidthBreakpoint={['600px', '800px']}>
      <ContextProvider reducer={reducers}>
        <ToastContainer position={toast.POSITION.TOP_CENTER} autoClose={4000} />
        <AuthProvider>
          <SocketProvider>
            <App />
          </SocketProvider>
        </AuthProvider>
        {EnvironmentLabel}
      </ContextProvider>
    </ScreenBreakpointProvider>
  )
}

ReactDOM.render(<Root />, document.getElementById('root'))

// Learn more about service workers: http://bit.ly/CRA-PWA
sw()
