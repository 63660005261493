import axios from 'axios'
import config from 'config'
import getUser from 'utils/userData'
import dispatchAction from 'utils/dispatcher'
import { encode } from 'utils/queryString'
import { toast } from 'react-toastify'
// action type strings should be unique across reducers so namespace them with the reducer name
export const actionTypes = {
  PROSPECT_CHAT_SUCCESS: 'PROSPECT_CHAT_SUCCESS',
  PROSPECT_CHAT: 'PROSPECT_CHAT',
  LIST_PROSPECT_CHAT_SUCCESS: 'LIST_PROSPECT_CHAT_SUCCESS',
  LIST_PROSPECT_CHAT: 'LIST_PROSPECT_CHAT'
}

const moduleName = 'Chat'
const path = '/api/prospect-chats'

// actions are where most of the business logic takes place
// they are dispatched by views or by other actions
/**
 *
 * @param {function} dispatch
 * @param {object} payload
 */
export const create = async (dispatch, payload, params) => {
  const user = getUser()
  const url = config.baseUrl + path + `?${encode(params)}`
  const action = async () => {
    const response = await axios.post(url, payload, {
      timeout: config.fetchingTimeout,
      headers: { Authorization: user.token }
    })
    if (response.status <= 301) {
      let { data } = response
      dispatch({
        type: actionTypes.PROSPECT_CHAT_SUCCESS,
        data
      })
      // toast.success(`${moduleName} has been saved`)
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  await dispatchAction(dispatch, actionTypes.PROSPECT_CHAT, action)
}

export const createNotifyWebsocket = async (dispatch, payload) => {
  const url = config.baseUrl + '/chat/marketing'
  const user = getUser()
  const action = async () => {
    const response = await axios.post(url, payload, {
      timeout: config.fetchingTimeout,
      headers: { Authorization: user.token }
    })
    if (response.status <= 301) {
      console.log('post success')
      // toast.success(`${moduleName} has been saved`)
    } else {
      const message = response.data && response.data.message
      console.log('action -> response', response)
      throw new Error(message || 'An error has been occured')
    }
  }
  await dispatchAction(dispatch, actionTypes.PROSPECT_CHAT, action)
}

/**
 *
 * @param {function} dispatch
 * @param {object} payload
 */
export const update = async (dispatch, payload) => {
  const user = getUser()
  const url = config.baseUrl + path
  const action = async () => {
    const response = await axios.put(url, payload, {
      timeout: config.fetchingTimeout,
      headers: { Authorization: user.token }
    })
    if (response.status <= 301) {
      let { data } = response
      dispatch({
        type: actionTypes.PROSPECT_CHAT_SUCCESS,
        data
      })
      toast.success(`${moduleName} has been updated`)
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  await dispatchAction(dispatch, actionTypes.PROSPECT_CHAT, action)
}

/**
 *
 * @param {function} dispatch
 * @param {object} params
 */
export const list = (dispatch, params) => {
  const url = config.baseUrl + path + '/search' + `?${encode(params)}`
  const action = async () => {
    const response = await axios.get(url, {
      timeout: config.fetchingTimeout,
      headers: { Authorization: getUser().token }
    })
    if (response.status <= 301) {
      const page = params.page
      let { data, headers } = response
      const count = headers['x-total-count'] || 0
      dispatch({
        type: actionTypes.LIST_PROSPECT_CHAT_SUCCESS,
        data,
        count,
        paging: { [page]: data }
      })
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  dispatchAction(dispatch, actionTypes.LIST_PROSPECT_CHAT, action)
}

export const resetList = dispatch => {
  dispatch({
    type: actionTypes.LIST_PROSPECT_CHAT_SUCCESS,
    reset: true
  })
}

/**
 *
 * @param {function} dispatch
 * @param {object} params
 */
export const remove = (dispatch, id) => {
  const url = config.baseUrl + path + `/${id}`
  const action = async () => {
    const response = await axios.delete(url, {
      timeout: config.fetchingTimeout,
      headers: { Authorization: getUser().token }
    })
    if (response.status <= 301) {
      let data = response.data
      dispatch({
        type: actionTypes.PROSPECT_CHAT_SUCCESS,
        data
      })
      toast.success(`${moduleName} has been deleted`)
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  dispatchAction(dispatch, actionTypes.PROSPECT_CHAT, action)
}

export const view = (dispatch, id) => {
  const url = config.baseUrl + path + `/${id}`
  const action = async () => {
    const response = await axios.get(url, {
      timeout: config.fetchingTimeout,
      headers: { Authorization: getUser().token }
    })
    if (response.status <= 301) {
      let data = response.data
      dispatch({
        type: actionTypes.PROSPECT_CHAT_SUCCESS,
        data
      })
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  dispatchAction(dispatch, actionTypes.PROSPECT_CHAT, action)
}

export const getChatByProspect = (dispatch, { prospectId, ...params }) => {
  const url = config.baseUrl + path + `/${prospectId}?prospectId=${prospectId}`
  const action = async () => {
    const response = await axios.get(url, {
      timeout: config.fetchingTimeout,
      headers: { Authorization: getUser().token }
    })
    if (response.status <= 301) {
      const page = params.page
      let { data, headers } = response
      const count = headers['x-total-count'] || 0
      dispatch({
        type: actionTypes.LIST_PROSPECT_CHAT_SUCCESS,
        data,
        count,
        paging: { [page]: data }
      })
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  dispatchAction(dispatch, actionTypes.LIST_PROSPECT_CHAT, action)
}

export const multiDelete = (dispatch, params) => {
  const url = config.baseUrl + path + `/multiDelete?${encode(params)}`
  const action = async () => {
    const response = await axios.delete(url, {
      timeout: config.fetchingTimeout,
      headers: { Authorization: getUser().token }
    })
    if (response.status <= 301) {
      let data = response.data
      dispatch({
        type: actionTypes.PROSPECT_CHAT_SUCCESS,
        data
      })
      toast.success(`${moduleName} has been deleted`)
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  dispatchAction(dispatch, actionTypes.PROSPECT_CHAT, action)
}
