import axios from 'axios'
import config from 'config'
import getUser from 'utils/userData'
import dispatchAction from 'utils/dispatcher'
import { encode } from 'utils/queryString'
import { toast } from 'react-toastify'
// action type strings should be unique across reducers so namespace them with the reducer name
export const actionTypes = {
  TODO_SUCCESS: 'TODO_SUCCESS',
  TODO: 'TODO',
  LIST_TODO_SUCCESS: 'LIST_TODO_SUCCESS',
  LIST_TODO: 'LIST_TODO'
}

const moduleName = 'Todo'
const path = '/api/to-do-lists'

// actions are where most of the business logic takes place
// they are dispatched by views or by other actions
/**
 *
 * @param {function} dispatch
 * @param {object} payload
 */
export const create = async (dispatch, payload, params) => {
  const user = getUser()
  const url = config.baseUrl + path + `?${encode(params)}`
  const action = async () => {
    const response = await axios.post(url, payload, {
      timeout: config.fetchingTimeout,
      headers: { Authorization: user.token }
    })
    if (response.status <= 301) {
      let { data } = response
      dispatch({
        type: actionTypes.TODO_SUCCESS,
        data
      })
      toast.success(`${moduleName} has been saved`)
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  await dispatchAction(dispatch, actionTypes.TODO, action)
}

/**
 *
 * @param {function} dispatch
 * @param {object} payload
 */
export const update = async (dispatch, payload) => {
  const user = getUser()
  const url = config.baseUrl + path
  const action = async () => {
    const response = await axios.put(url, payload, {
      timeout: config.fetchingTimeout,
      headers: { Authorization: user.token }
    })
    if (response.status <= 301) {
      let { data } = response
      dispatch({
        type: actionTypes.TODO_SUCCESS,
        data
      })
      toast.success(`${moduleName} has been updated`)
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  await dispatchAction(dispatch, actionTypes.TODO, action)
}

/**
 *
 * @param {function} dispatch
 * @param {object} params
 */
export const list = (dispatch, params) => {
  const url = config.baseUrl + path + `?${encode(params)}`
  const action = async () => {
    const response = await axios.get(url, {
      timeout: config.fetchingTimeout,
      headers: { Authorization: getUser().token }
    })
    if (response.status <= 301) {
      let data = response.data
      dispatch({
        type: actionTypes.LIST_TODO_SUCCESS,
        data
      })
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  dispatchAction(dispatch, actionTypes.LIST_TODO, action)
}

/**
 *
 * @param {function} dispatch
 * @param {object} params
 */
export const listByMonth = (dispatch, { month, ...params }) => {
  const url = config.baseUrl + path + `/user/${month}?${encode(params)}`
  const action = async () => {
    const response = await axios.get(url, {
      timeout: config.fetchingTimeout,
      headers: { Authorization: getUser().token }
    })
    if (response.status <= 301) {
      let data = response.data
      dispatch({
        type: actionTypes.LIST_TODO_SUCCESS,
        data,
        month
      })
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  dispatchAction(dispatch, actionTypes.LIST_TODO, action)
}

/**
 *
 * @param {function} dispatch
 * @param {object} params
 */
export const remove = (dispatch, id) => {
  const url = config.baseUrl + path + `/${id}`
  const action = async () => {
    const response = await axios.delete(url, {
      timeout: config.fetchingTimeout,
      headers: { Authorization: getUser().token }
    })
    if (response.status <= 301) {
      let data = response.data
      dispatch({
        type: actionTypes.TODO_SUCCESS,
        data
      })
      toast.success(`${moduleName} has been deleted`)
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  dispatchAction(dispatch, actionTypes.TODO, action)
}

export const view = (dispatch, id) => {
  const url = config.baseUrl + path + `/${id}`
  const action = async () => {
    const response = await axios.get(url, {
      timeout: config.fetchingTimeout,
      headers: { Authorization: getUser().token }
    })
    if (response.status <= 301) {
      let data = response.data
      dispatch({
        type: actionTypes.TODO_SUCCESS,
        data
      })
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  dispatchAction(dispatch, actionTypes.TODO, action)
}
