import { Layout } from 'antd'
import React from 'react'
import SidebarMenu from 'components/menu/sidebarMenu'
import { useScreenBreakpoint } from 'context'
import './style.css'
const { Sider } = Layout

const SideBar = ({ ...rest }) => {
  const [collapsed, setCollapsed] = React.useState(true)
  const menus = React.useMemo(() => <SidebarMenu collapsed={collapsed} handleCollapse={setCollapsed} />, [
    collapsed,
    setCollapsed
  ])

  const breakpoints = useScreenBreakpoint()
  const hasSidebar = breakpoints['600px']?.matches

  return (
    <>
      {hasSidebar ? (
        <Sider
          width={280}
          collapsedWidth={80}
          collapsed={collapsed}
          onCollapse={setCollapsed}
          className="root-sider"
          trigger={null}
          {...rest}
        >
          {menus}
        </Sider>
      ) : null}
    </>
  )
}
export default SideBar
