// reducers hold the store's state (the initialState object defines it)
// reducers also handle plain object actions and modify their state (immutably) accordingly
// this is the only way to change the store's state
// the other exports in this file are selectors, which is business logic that digests parts of the store's state
// for easier consumption by views

import { actionTypes } from './action'

/**
 * default set reducer that will be always return data
 * @param {object} state
 * @param {object} action
 * @param {string} actionType
 */
function setListReducer(state, action, actionType) {
  if (action.type === actionType) {
    const month = action.month
    let months = state?.months || []
    let all = state?.allData || []
    if (!months.includes(month)) {
      months.push(month)
      all = [...all, ...action.data]
    } else {
      const filterAll = all.filter(data => new Date(data.eventDate).getMonth() + 1 !== action.month)
      all = [...filterAll, ...action.data]
    }
    const todoState = { data: action.data, months, allData: all }
    return todoState
  }
  return state
}

function setDataReducer(state, action, actionType) {
  if (action.type === actionType) {
    return { data: action.data }
  }
  return state
}

export const todoReducer = (state = null, action) => setDataReducer(state, action, actionTypes.TODO_SUCCESS)

export const listTodoReducer = (state = null, action) => setListReducer(state, action, actionTypes.LIST_TODO_SUCCESS)
