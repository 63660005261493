import { Layout, Typography, Breadcrumb } from 'antd'
import { Link } from 'react-router-dom'
import React from 'react'
import { node, string } from 'prop-types'
import './style.css'
const { Content } = Layout
const { Title } = Typography

function content({ children, titlePage, breadcrumb, secondary }) {
  return (
    <section className="content-fade-in">
      <Content style={{ margin: '0 16px' }}>
        <Breadcrumb style={{ margin: '16px 0', minHeight: 21 }}>
          {breadcrumb ? (
            <>
              <Breadcrumb.Item>
                <Link to="/home">home</Link>
              </Breadcrumb.Item>
              {breadcrumb && breadcrumb.previous
                ? breadcrumb.previous.map((prev, i) => (
                    <Breadcrumb.Item key={i}>
                      <Link to={prev.to}>{prev.name}</Link>
                    </Breadcrumb.Item>
                  ))
                : null}
              <Breadcrumb.Item>
                <span style={{ textTransform: 'lowercase' }}>{breadcrumb && breadcrumb.current}</span>
              </Breadcrumb.Item>
            </>
          ) : (
            ''
          )}
        </Breadcrumb>
        <div
          style={{
            padding: 24,
            background: '#fff',
            minHeight: 360,
            boxShadow: '0px 1px 6px 1px rgba(200,200,200, 0.1)',
            borderRadius: '10px'
          }}
        >
          <div className="content-heading">
            <Title className="text-logo" level={2}>
              {titlePage}
            </Title>
            <section>{secondary}</section>
          </div>
          {children}
        </div>
      </Content>
    </section>
  )
}

content.propTypes = { children: node, titlePage: string, breadcrumb: node, secondary: node }

export default content
