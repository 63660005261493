import axios from 'axios'
import config from 'config'
import getUser from 'utils/userData'
import dispatchAction from 'utils/dispatcher'
import { encode } from 'utils/queryString'
import { toast } from 'react-toastify'
// action type strings should be unique across reducers so namespace them with the reducer name
export const actionTypes = {
  COMMISSION_ACCOUNT_SUCCESS: 'COMMISSION_ACCOUNT_SUCCESS',
  COMMISSION_ACCOUNT: 'COMMISSION_ACCOUNT',
  LIST_COMMISSION_ACCOUNT_SUCCESS: 'LIST_COMMISSION_ACCOUNT_SUCCESS',
  LIST_COMMISSION_ACCOUNT: 'LIST_COMMISSION_ACCOUNT',
  LIST_COMMISSION_BRANCH_SUCCESS: 'LIST_COMMISSION_BRANCH_SUCCESS',
  LIST_COMMISSION_BRANCH: 'LIST_COMMISSION_BRANCH',
  LIST_COMMISSION_USER_SUCCESS: 'LIST_COMMISSION_USER_SUCCESS',
  LIST_COMMISSION_USER: 'LIST_COMMISSION_USER'
}

const moduleName = 'Calculate Commission'
const path = '/api/calculate-commission'

// actions are where most of the business logic takes place
// they are dispatched by views or by other actions
/**
 *
 * @param {function} dispatch
 * @param {object} payload
 */
export const create = async (dispatch, payload, params) => {
  const user = getUser()
  const url = config.baseUrl + '/api/calculate-commission-account-bonus' + `?${encode(params)}`
  const action = async () => {
    const response = await axios.post(url, payload, {
      timeout: 600000 || config.fetchingTimeout,
      headers: { Authorization: user.token }
    })
    if (response.status <= 301) {
      let { data } = response
      dispatch({
        type: actionTypes.COMMISSION_ACCOUNT_SUCCESS,
        data
      })
      toast.success(`${moduleName} has been saved`)
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  await dispatchAction(dispatch, actionTypes.COMMISSION_ACCOUNT, action)
}

/**
 *
 * @param {function} dispatch
 * @param {object} payload
 */
export const update = async (dispatch, payload) => {
  const user = getUser()
  const url = config.baseUrl + path
  const action = async () => {
    const response = await axios.put(url, payload, {
      timeout: 600000 || config.fetchingTimeout,
      headers: { Authorization: user.token }
    })
    if (response.status <= 301) {
      let { data } = response
      dispatch({
        type: actionTypes.COMMISSION_ACCOUNT_SUCCESS,
        data
      })
      toast.success(`${moduleName} has been updated`)
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  await dispatchAction(dispatch, actionTypes.COMMISSION_ACCOUNT, action)
}

/**
 *
 * @param {function} dispatch
 * @param {object} params
 */

/**
 *
 * @param {*} dispatch
 * @param {*} params
 */

export const list = (dispatch, params) => {
  const page = params.page
  const url = config.baseUrl + path + `/search?${encode(params)}`
  const action = async () => {
    const response = await axios.get(url, {
      timeout: 600000 || config.fetchingTimeout,
      headers: { Authorization: getUser().token }
    })
    if (response.status <= 301) {
      let { data, headers } = response
      const count = headers['x-total-count'] || 0
      dispatch({
        type: actionTypes.LIST_COMMISSION_ACCOUNT_SUCCESS,
        data,
        count,
        paging: { [page]: data }
      })
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  dispatchAction(dispatch, actionTypes.LIST_COMMISSION_ACCOUNT, action)
}

export const resetList = dispatch => {
  dispatch({
    type: actionTypes.LIST_COMMISSION_ACCOUNT_SUCCESS,
    reset: true
  })
}
export const resetListAccountCommision = dispatch => {
  dispatch({
    type: actionTypes.LIST_COMMISSION_ACCOUNT_SUCCESS,
    reset: true,
    data: null
  })
}
export const resetListBranchCommision = dispatch => {
  dispatch({
    type: actionTypes.LIST_COMMISSION_BRANCH_SUCCESS,
    reset: true,
    data: null
  })
}
export const resetListUserCommision = dispatch => {
  dispatch({
    type: actionTypes.LIST_COMMISSION_USER_SUCCESS,
    reset: true,
    data: null
  })
}
export const listBranchCommision = (dispatch, params) => {
  console.log('1', params)
  console.log('2', encode(params))
  const url =
    config.baseUrl +
    '/api/calculate-commission-branch-bonus' +
    `?floating=${!params.floating ? 0 : params.floating}` +
    `&startDate=${params.startDate}` +
    `&endDate=${params.endDate}` +
    `&bonusDate=${!params.bonusDate ? '1970-01-01' : params.bonusDate}`
  const action = async () => {
    const response = await axios.get(url, {
      timeout: 600000 || config.fetchingTimeout,
      headers: { Authorization: getUser().token }
    })
    if (response.status <= 301) {
      let { data, headers } = response
      const count = headers['x-total-count'] || 0
      dispatch({
        type: actionTypes.LIST_COMMISSION_BRANCH_SUCCESS,
        data,
        count
      })
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  dispatchAction(dispatch, actionTypes.LIST_COMMISSION_BRANCH, action)
}

export const listAccountCommision = (dispatch, params) => {
  const url =
    config.baseUrl +
    '/api/calculate-commission-account-bonus' +
    `?floating=${!params.floating ? 0 : params.floating}` +
    `&startDate=${params.startDate}` +
    `&endDate=${params.endDate}` +
    `&branchOfficeId=${params.branchOfficeId}` +
    `&employeeId=${params.employeeId}` +
    `&bonusDate=${!params.bonusDate ? '1970-01-01' : params.bonusDate}`
  const action = async () => {
    const response = await axios.get(url, {
      timeout: 600000 || config.fetchingTimeout,
      headers: { Authorization: getUser().token }
    })
    if (response.status <= 301) {
      let { data, headers } = response
      const count = headers['x-total-count'] || 0
      dispatch({
        type: actionTypes.LIST_COMMISSION_ACCOUNT_SUCCESS,
        data,
        count
      })
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  dispatchAction(dispatch, actionTypes.LIST_COMMISSION_ACCOUNT, action)
}

export const listUserCommision = (dispatch, params) => {
  const url =
    config.baseUrl +
    '/api/calculate-commission-user-bonus' +
    `?floating=${!params.floating ? 0 : params.floating}` +
    `&startDate=${params.startDate}` +
    `&endDate=${params.endDate}` +
    `&branchOfficeId=${params.branchOfficeId}` +
    `&bonusDate=${!params.bonusDate ? '1970-01-01' : params.bonusDate}`
  const action = async () => {
    const response = await axios.get(url, {
      timeout: 600000 || config.fetchingTimeout,
      headers: { Authorization: getUser().token }
    })
    if (response.status <= 301) {
      let { data, headers } = response
      const count = headers['x-total-count'] || 0
      dispatch({
        type: actionTypes.LIST_COMMISSION_USER_SUCCESS,
        data,
        count
      })
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  dispatchAction(dispatch, actionTypes.LIST_COMMISSION_USER, action)
}

/**
 *
 * @param {function} dispatch
 * @param {object} params
 */
export const remove = (dispatch, id) => {
  const url = config.baseUrl + path + `/${id}`
  const action = async () => {
    const response = await axios.delete(url, {
      timeout: 600000 || config.fetchingTimeout,
      headers: { Authorization: getUser().token }
    })
    if (response.status <= 301) {
      let data = response.data
      dispatch({
        type: actionTypes.COMMISSION_ACCOUNT_SUCCESS,
        data
      })
      toast.success(`${moduleName} has been deleted`)
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  dispatchAction(dispatch, actionTypes.COMMISSION_ACCOUNT, action)
}

export const view = (dispatch, id) => {
  const url = config.baseUrl + path + `/${id}`
  const action = async () => {
    const response = await axios.get(url, {
      timeout: 600000 || config.fetchingTimeout,
      headers: { Authorization: getUser().token }
    })
    if (response.status <= 301) {
      let data = response.data
      dispatch({
        type: actionTypes.COMMISSION_ACCOUNT_SUCCESS,
        data
      })
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  dispatchAction(dispatch, actionTypes.COMMISSION_ACCOUNT, action)
}
