import React, { useContext, useState, useReducer, useEffect, useRef } from 'react'
import { node, func, array } from 'prop-types'
import devTool from './reduxDevTool'
import { isLogin } from 'utils/userData'

// create a global context

const Context = React.createContext()
export const AuthContext = React.createContext()
export const SocketContext = React.createContext({})
export const ScreenBreakpointContext = React.createContext()

// socket

export const SocketProvider = ({ children }) => {
  const [data, setData] = React.useState({})
  return <SocketContext.Provider value={[data, setData]}>{children}</SocketContext.Provider>
}
SocketProvider.propTypes = { children: node }

export const useSocket = () => useContext(SocketContext)

// auth

export const AuthProvider = ({ children }) => {
  const [login, setLoginStatus] = React.useState(isLogin())
  return <AuthContext.Provider value={{ login, setLoginStatus }}>{children}</AuthContext.Provider>
}
AuthProvider.propTypes = { children: node }

export const useAuth = () => useContext(AuthContext)

// screen breakpoint

export const ScreenBreakpointProvider = ({ children, minWidthBreakpoint = [] }) => {
  const [data, setData] = React.useState({})
  const handleGetBreakpoint = breakpoints => {
    return breakpoints.reduce((acc, minWidthValue) => {
      acc[minWidthValue] = window.matchMedia(`(min-width: ${minWidthValue})`)

      return acc
    }, {})
  }
  React.useEffect(() => {
    setData(handleGetBreakpoint(minWidthBreakpoint))
  }, []) // eslint-disable-line
  return <ScreenBreakpointContext.Provider value={data}>{children}</ScreenBreakpointContext.Provider>
}
ScreenBreakpointProvider.propTypes = { children: node, minWidthBreakpoint: array.isRequired }

export const useScreenBreakpoint = () => useContext(ScreenBreakpointContext)

// Provider to be wrapped on the root of application

export const Provider = ({ children, reducer }) => {
  const [store, dispatch] = useReducer(reducer, {})
  const [state, setState] = useState({ isLoaded: false })
  useEffect(() => {
    dispatch({ type: '@init' })
    setState({ isLoaded: true })
  }, [])
  devTool(store)
  return <Context.Provider value={{ dispatch, store }}>{state.isLoaded ? children : false}</Context.Provider>
}
Provider.propTypes = {
  children: node,
  reducer: func
}

/**
 * use context to get global state
 * @param {string} key optionally
 * if key provided it'll return only property required
 */
export const useStateValue = (key, mockData) => {
  const dev = process.env.NODE_ENV === 'development'
  let { store, dispatch } = useContext(Context)

  // without request to backend use the mockData to simulate your data
  if (mockData && dev) {
    store = { ...store, [key]: { ...store[key], ...mockData } }
  }
  return key ? [store[key], dispatch] : [store, dispatch]
}

/**
 * use context to get error and loading state
 * @param {string} key is required
 */
export const useStateDefault = key => {
  const { store, dispatch } = useContext(Context)
  const { error, loading } = store
  return [error[key], loading[key], dispatch]
}

/**
 * usePrevious is a method to get tracked previous value of a state or props
 * @param {*} value state to detect the previous value
 */
export function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}
