import { Layout } from 'antd'
import React from 'react'
import { node, bool, any } from 'prop-types'
import { useScreenBreakpoint } from 'context'
function MainLayout({ children }) {
  const breakpoints = useScreenBreakpoint()
  const hasSidebar = breakpoints['600px']?.matches
  return (
    <Layout
      hasSider={hasSidebar}
      style={{
        minHeight: '100vh'
      }}
    >
      {children}
    </Layout>
  )
}

MainLayout.propTypes = {
  children: node,
  withHeader: bool,
  propsHeader: any
}

export default MainLayout
