import { toast } from 'react-toastify'
import { actionLogout } from 'context/auth/action'
import NativeRouter from './router'

let toastId = ''

export default async function dispatchAction(dispatch, actionType, action) {
  const dev = process.env.NODE_ENV === 'development'
  const onRequest = '_REQUEST'
  const onFailure = '_FAILURE'
  dispatch({ type: actionType + onRequest })
  try {
    await action()
  } catch (error) {
    const response = error.response || {}
    if (dev) {
      console.trace()
      console.info('[Error]:', response.data || response)
    }
    const detailMessage = (response.data && response.data.detail) || (response.data && response.data.message)
    const textMessage =
      response.data && response.data.title && response.data.title + ` ${detailMessage ? '(' + detailMessage + ')' : ''}`
    const dataMessage = response.data && response.data.messages && response.data.messages
    const message = dataMessage || textMessage || error.message
    if (!toast.isActive(toastId)) {
      toastId = toast.error(message, { toastId: message })
    }
    if (response.status === 401) {
      actionLogout(dispatch)
      NativeRouter.load('/')
    }
    dispatch({ type: actionType + onFailure, error })
  }
}
