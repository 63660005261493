// action type strings should be unique across reducers so namespace them with the reducer name
export const actionTypes = {
  VIEW_NOTIFICATION: 'VIEW_NOTIFICATION',
  VIEW_NOTIFICATION_TYPE: 'VIEW_NOTIFICATION_TYPE'
}

// actions are where most of the business logic takes place
// they are dispatched by views or by other actions
/**
 *
 * @param {function} dispatch
 * @param {object} payload
 */

export const toggleViewNotification = (dispatch, value) => {
  dispatch({
    type: actionTypes.VIEW_NOTIFICATION,
    value
  })
}
export const viewNotificationType = (dispatch, value) => {
  dispatch({
    type: actionTypes.VIEW_NOTIFICATION_TYPE,
    value
  })
}
