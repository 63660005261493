import { lazy } from 'react'
import LoginPage from 'pages/login'
import ForgotPage from 'pages/forgot-password'
import NewPasswordPage from 'pages/new-password'
import NotFoundPage from 'pages/notfound'

// only lazyload the pages protected and with header
const HomePage = lazy(() => import('pages/home'))
const UserPage = lazy(() => import('pages/user'))
const RolePage = lazy(() => import('pages/role'))
const NtrWhatsapp = lazy(() => import('pages/ntr-whatsapp'))
const Commision = lazy(() => import('pages/bonus'))
// const GradeLevelPage = lazy(() => import('pages/grade-level'))
const MyProfilePage = lazy(() => import('pages/my-profile'))
const ModulePage = lazy(() => import('pages/module'))
const BranchOfficeLocationPage = lazy(() => import('pages/branch-office-location'))
const BranchOfficePage = lazy(() => import('pages/branch-office'))
const BranchOfficeGroupPage = lazy(() => import('pages/branch-office-group'))
const VehiclePage = lazy(() => import('pages/vehicle'))
const PositionPage = lazy(() => import('pages/position'))
const OrganizationStructurePage = lazy(() => import('pages/organization-structure'))
const VehicleManagementPage = lazy(() => import('pages/vehicle-management'))
const MarketingSchedulePage = lazy(() => import('pages/marketing-schedule'))
const PopulateMT5Page = lazy(() => import('pages/populate-mt5'))
const CommissionSettingPage = lazy(() => import('pages/commission-setting'))
const AccountRatePage = lazy(() => import('pages/account-rate'))
const CalculateCommissionPage = lazy(() => import('pages/calculate-commission'))
const TaxPerUserPage = lazy(() => import('pages/tax-per-user'))
const ReserveFundPage = lazy(() => import('pages/reserve-fund'))
const MsReserveFundPage = lazy(() => import('pages/ms-reserve-fund'))
const AccountGroupPage = lazy(() => import('pages/account-group'))
const MsPtkpPage = lazy(() => import('pages/ms-ptkp'))
const TaxAccumulationPage = lazy(() => import('pages/tax-accumulation'))
const VisualizationReportPage = lazy(() => import('pages/visualization-report'))
const TodoPage = lazy(() => import('pages/todo'))
const NewsPage = lazy(() => import('pages/news'))
const ReUploadHistoryPage = lazy(() => import('pages/reupload-history'))
const ClientEquityPage = lazy(() => import('pages/client-equity'))
const NetTradingResultPage = lazy(() => import('pages/net-trading-result'))
const NetTradingResultAccountingPage = lazy(() => import('pages/net-trading-result-accounting'))
const CategoryPage = lazy(() => import('pages/category'))
const MarketingTargetPage = lazy(() => import('pages/marketing-target'))

export const defaultRoutes = [
  {
    path: '/forgot-password',
    exact: true,
    Component: ForgotPage
  },
  {
    path: '/new-password',
    exact: true,
    Component: NewPasswordPage
  },
  {
    path: '/',
    exact: true,
    Component: LoginPage
  },
  {
    path: '/my-profile',
    exact: true,
    Component: MyProfilePage,
    isProtected: true
  },
  {
    path: '*',
    Component: NotFoundPage
  }
]

export const protectedRoutes = [
  {
    path: '/home',
    exact: true,
    Component: HomePage,
    isProtected: true
  },
  {
    path: '/user',
    exact: true,
    Component: UserPage,
    isProtected: true
  },
  {
    path: '/role',
    exact: true,
    Component: RolePage,
    isProtected: true
  },
  // {
  //   path: '/grade-level',
  //   exact: true,
  //   component: GradeLevelPage,
  //   isProtected: true
  // },
  {
    path: '/module',
    exact: true,
    Component: ModulePage,
    isProtected: true
  },
  {
    path: '/bonus',
    exact: true,
    Component: Commision,
    isProtected: true
  },
  {
    path: '/branch-office-location',
    exact: true,
    Component: BranchOfficeLocationPage,
    isProtected: true
  },
  {
    path: '/branch-office',
    exact: true,
    Component: BranchOfficePage,
    isProtected: true
  },
  {
    path: '/branch-office-group',
    exact: true,
    Component: BranchOfficeGroupPage,
    isProtected: true
  },
  {
    path: '/vehicle',
    exact: true,
    Component: VehiclePage,
    isProtected: true
  },
  {
    path: '/position',
    exact: true,
    Component: PositionPage,
    isProtected: true
  },
  {
    path: '/organization-structure',
    exact: true,
    Component: OrganizationStructurePage,
    isProtected: true
  },
  {
    path: '/vehicle-management',
    exact: true,
    Component: VehicleManagementPage,
    isProtected: true
  },
  {
    path: '/marketing-schedule',
    exact: true,
    Component: MarketingSchedulePage,
    isProtected: true
  },
  {
    path: '/populate-mt5',
    exact: true,
    Component: PopulateMT5Page,
    isProtected: true
  },
  {
    path: '/commission-setting',
    exact: true,
    Component: CommissionSettingPage,
    isProtected: true
  },
  {
    path: '/account-rate',
    exact: true,
    Component: AccountRatePage,
    isProtected: true
  },
  {
    path: '/calculate-commission',
    exact: true,
    Component: CalculateCommissionPage,
    isProtected: true
  },
  {
    path: '/tax-per-user',
    exact: true,
    Component: TaxPerUserPage,
    isProtected: true
  },
  {
    path: '/reserve-fund',
    exact: true,
    Component: ReserveFundPage,
    isProtected: true
  },
  {
    path: '/account-group',
    exact: true,
    Component: AccountGroupPage,
    isProtected: true
  },
  {
    path: '/ms-reserve-fund',
    exact: true,
    Component: MsReserveFundPage,
    isProtected: true
  },
  {
    path: '/ms-ptkp',
    exact: true,
    Component: MsPtkpPage,
    isProtected: true
  },
  {
    path: '/tax-accumulation',
    exact: true,
    Component: TaxAccumulationPage,
    isProtected: true
  },
  {
    path: '/visualization-report',
    exact: true,
    Component: VisualizationReportPage,
    isProtected: true
  },
  {
    path: '/ntr-whatsApp',
    exact: true,
    Component: NtrWhatsapp,
    isProtected: true
  },
  {
    path: '/todo-list',
    exact: true,
    Component: TodoPage,
    isProtected: true
  },
  {
    path: '/news',
    exact: true,
    Component: NewsPage,
    isProtected: true
  },
  {
    path: '/reupload-history',
    exact: true,
    Component: ReUploadHistoryPage,
    isProtected: true
  },
  {
    path: '/client-equity',
    exact: true,
    Component: ClientEquityPage,
    isProtected: true
  },
  {
    path: '/net-trading-result',
    exact: true,
    Component: NetTradingResultPage,
    isProtected: true
  },
  {
    path: '/category',
    exact: true,
    Component: CategoryPage,
    isProtected: true
  },
  {
    path: '/net-trading-result-accounting',
    exact: true,
    Component: NetTradingResultAccountingPage,
    isProtected: true
  },
  {
    path: '/marketing-target',
    exact: true,
    Component: MarketingTargetPage,
    isProtected: true
  }
]
