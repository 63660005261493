import {
  userReducer as userProfileReducer,
  forgotPasswordReducer,
  activationAccountReducer,
  changePasswordReducer,
  resetPasswordReducer,
  resendActivationReducer
} from './auth/reducer'

import {
  branchOfficeGroupDetailReducer,
  listBranchOfficeGroupDetailReducer
} from './branch-office-group-detail/reducer'

import {
  vehicleManagementReducer,
  listVehicleManagementReducer,
  availableVehicleManagementReducer
} from './vehicle-management/reducer'

import {
  accountCommissionHeaderDetailReducer,
  listAccountCommissionHeaderDetailReducer
} from './account-commission-header-detail/reducer'

import {
  prospectStatusDetailReducer,
  prospectStatusReducer,
  listProspectStatusReducer
} from './prospect-status/reducer'

import {
  commissionAccountReducer,
  listCommissionAccountReducer,
  listCommissionBranchReducer,
  listCommissionUserReducer
} from './calculate-commission/reducer'
import {
  getcommissionAccountReducer,
  getlistCommissionAccountReducer,
  getlistCommissionBranchReducer,
  getlistCommissionUserReducer
} from './bonus/reducer'

import {
  listReservedFundAccountReducer,
  listReservedFundBranchReducer,
  listMsReservedFundReducer,
  msReservedFundReducer
} from './reserved-fund/reducer'

import {
  listTaxAccumulationBranchReducer,
  listTaxAccumulationUserDetailReducer,
  listTaxAccumulationUserReducer
} from './tax-accumulation/reducer'

import {
  prospectReducer,
  listProspectReducer,
  listProspectHistoryReducer,
  listProspectNotificationReducer,
  listVisualizationReducer,
  listVisualizationBranchReducer,
  listVisualizationAccountReducer
} from './prospect/reducer'

import {
  listNetTradingResultAccountReducer,
  listNetTradingResultAccountTypeReducer,
  listNetTradingResultBranchReducer,
  netTradingResultTimeReducer,
  listNetTradingResultAccountingAccountReducer,
  listNetTradingResultAccountingBranchReducer,
  listNetTradingResultAccountingDealNumberReducer,
  getFileExcel
} from './net-trading-result/reducer'

import { errorReducer, loadingReducer, collapsedReducer } from './default/reducer'
import { userReducer, listUserReducer, userLockReducer, listUserMasterReducer } from './user/reducer'
import { branchReducer, listBranchReducer } from './branch/reducer'
import { masterUserReducer, masterModuleReducer, masterDataReducer } from './master/reducer'
import { martialStatusReducer, listMartialStatusReducer } from './martialStatus/reducer'
import { ptkpReducer, listPtkpReducer } from './ptkp/reducer'
import { religionReducer, listReligionReducer } from './religion/reducer'
import { roleReducer, listRoleReducer } from './role/reducer'
import { roleAccessReducer, listRoleAccessReducer } from './role-access/reducer'
import { gradeLevelReducer, listGradeLevelReducer } from './gradeLevel/reducer'
import { moduleReducer, listModuleReducer } from './module/reducer'
import { cityReducer, listCityReducer } from './city/reducer'
import { branchOfficeReducer, listBranchOfficeReducer } from './branch-office/reducer'
import { branchOfficeGroupReducer, listBranchOfficeGroupReducer } from './branch-office-group/reducer'
import { branchOfficeLocationReducer, listBranchOfficeLocationReducer } from './branch-office-location/reducer'
import { vehicleReducer, listVehicleReducer } from './vehicle/reducer'
import { positionReducer, listPositionReducer } from './position/reducer'
import { orderReducer, listOrderReducer } from './order/reducer'
import { orderHistoryReducer, listOrderHistoryReducer } from './order-history/reducer'
import { organizationStructureReducer, listOrganizationStructureReducer } from './organization-structure/reducer'
import { prospectChatReducer, listProspectChatReducer } from './prospect-chat/reducer'
import { accountRateReducer, accountRateDropdownReducer, listAccountRateReducer } from './account-rate/reducer'
import { accountCommissionHeaderReducer, listAccountCommissionHeaderReducer } from './account-commission-header/reducer'
import { userMt4Reducer, listUserMt4Reducer } from './user-mt4/reducer'
import { listTaxPerAccountReducer, listTaxPerUserReducer } from './tax-per-user/reducer'
import { accountGroupReducer, listAccountGroupReducer } from './account-group/reducer'
import { msPtkpReducer, listMsPtkpReducer } from './ms-ptkp/reducer'
import { viewNotificationReducer, viewNotificationTypeReducer } from './notification/reducer'
import { todoReducer, listTodoReducer } from './todo/reducer'
import { clientEquityReducer, listClientEquityReducer } from './client-equity/reducer'
import { newsReducer, listNewsReducer, listTypeNewsReducer, listNewsNotificationReducer } from './news/reducer'
import { listTotalLotReducer } from './home/reducer'
import { listCategoryReducer, categoryReducer } from './category/reducer'
import { marketingTargetReducer, listMarketingTargetReducer } from './marketing-target/reducer'
import { generateNtrReducer, generatePreviewNtrReducer } from './ntr-whatsapp/reducer'

export const reducers = {
  loading: loadingReducer,
  error: errorReducer,
  userProfile: userProfileReducer,
  user: userReducer,
  listUser: listUserReducer,
  collapsed: collapsedReducer,
  branch: branchReducer,
  listBranch: listBranchReducer,
  martialStatus: martialStatusReducer,
  listMartialStatus: listMartialStatusReducer,
  ptkp: ptkpReducer,
  listPtkp: listPtkpReducer,
  religion: religionReducer,
  listReligion: listReligionReducer,
  role: roleReducer,
  listRole: listRoleReducer,
  gradeLevel: gradeLevelReducer,
  listGradeLevel: listGradeLevelReducer,
  forgotPassword: forgotPasswordReducer,
  activationAccount: activationAccountReducer,
  changePassword: changePasswordReducer,
  resetPassword: resetPasswordReducer,
  userLock: userLockReducer,
  resendActivation: resendActivationReducer,
  listUserMaster: listUserMasterReducer,
  module: moduleReducer,
  listModule: listModuleReducer,
  masterUser: masterUserReducer,
  masterModule: masterModuleReducer,
  roleAccess: roleAccessReducer,
  listRoleAccess: listRoleAccessReducer,
  city: cityReducer,
  listCity: listCityReducer,
  branchOffice: branchOfficeReducer,
  listBranchOffice: listBranchOfficeReducer,
  branchOfficeLocation: branchOfficeLocationReducer,
  listBranchOfficeLocation: listBranchOfficeLocationReducer,
  branchOfficeGroup: branchOfficeGroupReducer,
  listBranchOfficeGroup: listBranchOfficeGroupReducer,
  branchOfficeGroupDetail: branchOfficeGroupDetailReducer,
  listBranchOfficeGroupDetail: listBranchOfficeGroupDetailReducer,
  masterData: masterDataReducer,
  vehicle: vehicleReducer,
  listVehicle: listVehicleReducer,
  position: positionReducer,
  listPosition: listPositionReducer,
  order: orderReducer,
  listOrder: listOrderReducer,
  orderHistory: orderHistoryReducer,
  listOrderHistory: listOrderHistoryReducer,
  organizationStructure: organizationStructureReducer,
  vehicleManagement: vehicleManagementReducer,
  listVehicleManagement: listVehicleManagementReducer,
  availableVehicleManagement: availableVehicleManagementReducer,
  prospect: prospectReducer,
  listProspect: listProspectReducer,
  prospectChat: prospectChatReducer,
  listProspectChat: listProspectChatReducer,
  listOrganizationStructure: listOrganizationStructureReducer,
  accountRate: accountRateReducer,
  listAccountRate: listAccountRateReducer,
  accountCommissionHeader: accountCommissionHeaderReducer,
  listAccountCommissionHeader: listAccountCommissionHeaderReducer,
  accountCommissionHeaderDetail: accountCommissionHeaderDetailReducer,
  listAccountCommissionHeaderDetail: listAccountCommissionHeaderDetailReducer,
  userMt4: userMt4Reducer,
  listUserMt4: listUserMt4Reducer,
  prospectStatus: prospectStatusReducer,
  prospectStatusDetail: prospectStatusDetailReducer,
  listProspectStatus: listProspectStatusReducer,
  bonus: getcommissionAccountReducer,
  commissionAccount: commissionAccountReducer,
  listBonusAccount: getlistCommissionAccountReducer,
  listBonusBranch: getlistCommissionBranchReducer,
  listBonusUser: getlistCommissionUserReducer,
  listCommissionAccount: listCommissionAccountReducer,
  listCommissionBranch: listCommissionBranchReducer,
  listCommissionUser: listCommissionUserReducer,
  listTaxPerAccount: listTaxPerAccountReducer,
  listTaxPerUser: listTaxPerUserReducer,
  listReservedFundAccount: listReservedFundAccountReducer,
  listReservedFundBranch: listReservedFundBranchReducer,
  listMsReservedFund: listMsReservedFundReducer,
  msReservedFund: msReservedFundReducer,
  accountGroup: accountGroupReducer,
  listAccountGroup: listAccountGroupReducer,
  accountRateDropdown: accountRateDropdownReducer,
  msPtkp: msPtkpReducer,
  listMsPtkp: listMsPtkpReducer,
  listProspectHistory: listProspectHistoryReducer,
  listTaxAccumulationBranch: listTaxAccumulationBranchReducer,
  listTaxAccumulationUser: listTaxAccumulationUserReducer,
  listTaxAccumulationUserDetail: listTaxAccumulationUserDetailReducer,
  listProspectNotification: listProspectNotificationReducer,
  listVisualization: listVisualizationReducer,
  listVisualizationBranch: listVisualizationBranchReducer,
  viewNotification: viewNotificationReducer,
  viewNotifType: viewNotificationTypeReducer,
  todo: todoReducer,
  listTodo: listTodoReducer,
  news: newsReducer,
  listNews: listNewsReducer,
  listTypeNews: listTypeNewsReducer,
  listNewsNotification: listNewsNotificationReducer,
  clientEquity: clientEquityReducer,
  listClientEquity: listClientEquityReducer,
  listTotalLot: listTotalLotReducer,
  listVisualizationAccount: listVisualizationAccountReducer,
  listNetTradingResultAccount: listNetTradingResultAccountReducer,
  listNetTradingResultBranch: listNetTradingResultBranchReducer,
  listNetTradingResultAccountType: listNetTradingResultAccountTypeReducer,
  listCategory: listCategoryReducer,
  category: categoryReducer,
  netTradingResultTime: netTradingResultTimeReducer,
  listNetTradingResultAccountingAccount: listNetTradingResultAccountingAccountReducer,
  listNetTradingResultAccountingBranch: listNetTradingResultAccountingBranchReducer,
  listNetTradingResultAccountingDealNumber: listNetTradingResultAccountingDealNumberReducer,
  marketingTarget: marketingTargetReducer,
  listMarketingTarget: listMarketingTargetReducer,
  generateNtr: generateNtrReducer,
  previewNtr: generatePreviewNtrReducer,
  generateFileExcelNTRAccounting: getFileExcel
}

const combineReducers = reducer => {
  return (state = {}, action) => {
    const keys = Object.keys(reducer)
    const nextReducers = {}
    for (let i = 0; i < keys.length; i++) {
      const invoke = reducer[keys[i]](state[keys[i]], action)
      nextReducers[keys[i]] = invoke
    }
    return nextReducers
  }
}

export default combineReducers(reducers)
