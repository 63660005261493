import axios from 'axios'
import config from 'config'
import getUser from 'utils/userData'
import dispatchAction from 'utils/dispatcher'
import { encode } from 'utils/queryString'

// action type strings should be unique across reducers so namespace them with the reducer name
export const actionTypes = {
  MASTER_USER_SUCCESS: 'MASTER_USER_SUCCESS',
  MASTER_USER: 'MASTER_USER',
  MASTER_MODULE_SUCCESS: 'MASTER_MODULE_SUCCESS',
  MASTER_MODULE: 'MASTER_MODULE'
}

export const listUserMaster = (dispatch, params) => {
  const url = `${config.baseUrl}/api/users/search?${encode(params)}`
  const action = async () => {
    const response = await axios.get(url, {
      timeout: config.fetchingTimeout,
      headers: { Authorization: getUser().token }
    })
    if (response.status <= 301) {
      let { data, headers } = response
      const count = headers['x-total-count'] || 0
      dispatch({
        type: actionTypes.MASTER_USER_SUCCESS,
        data,
        count,
        paging: {}
      })
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  dispatchAction(dispatch, actionTypes.MASTER_USER, action)
}

export const listModuleMaster = (dispatch, params) => {
  const url = `${config.baseUrl}/api/module-ms/search?${encode(params)}`
  const action = async () => {
    const response = await axios.get(url, {
      timeout: config.fetchingTimeout,
      headers: { Authorization: getUser().token }
    })
    if (response.status <= 301) {
      let { data, headers } = response
      const count = headers['x-total-count'] || 0
      dispatch({
        type: actionTypes.MASTER_MODULE_SUCCESS,
        data,
        count,
        paging: {}
      })
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  dispatchAction(dispatch, actionTypes.MASTER_MODULE, action)
}

export const listMaster = (dispatch, params) => options => {
  const [source, actionType, keyMap] = options
  const url = config.baseUrl + '/api/' + source + `?${encode(params)}`
  const action = async () => {
    const response = await axios.get(url, {
      timeout: config.fetchingTimeout,
      headers: { Authorization: getUser().token }
    })
    if (response.status <= 301) {
      let { data, headers } = response
      const count = headers['x-total-count'] || 0
      const actionTypeSuccess = actionType + '_MASTER_SUCCESS'
      const res = {
        type: actionTypeSuccess,
        data,
        count,
        paging: {}
      }
      //  convert data to map to easy access
      if (keyMap) {
        const dataMap = new Map()
        for (let i = 0; i < data.length; i++) {
          const item = data[i]
          const id = item[keyMap]
          dataMap.set(id, item)
        }
        res.dataMap = dataMap
      }

      dispatch(res)
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  dispatchAction(dispatch, actionType + '_MASTER', action)
}
