import React from 'react'
import { useStateValue, usePrevious } from 'context'
import { array, string, object, number, bool } from 'prop-types'
import { toggleViewNotification, viewNotificationType } from 'context/notification/action'
import ReminderNotification from './ReminderNotification'
import NewsNotification from './NewsNotification'
const Notification = ({ messages, visible }) => {
  const [, dispatch] = useStateValue('user')
  const [onView, setOnView] = React.useState('reminder')
  const [listNewsNotification] = useStateValue('listNewsNotification')
  const [viewType] = useStateValue('viewNotifType')
  const dataNotification = listNewsNotification?.data || []

  // React.useEffect(() => {
  //   if (messages.length !== 0) {
  //     setOnView('reminder')
  //   }else{
  //     setOnView('news')
  //   }
  // }, [messages])
  //  const isVisible = {

  //  }

  function handleCloseReminder() {
    toggleViewNotification(dispatch, false)
    setTimeout(() => {
      if (dataNotification[0]) {
        // viewNotificationType(dispatch, 'news')
        setOnView('news')
        toggleViewNotification(dispatch, true)
      }
    }, 500)
  }

  function handleCloseNews() {
    setTimeout(() => {
      toggleViewNotification(dispatch, false)
      // viewNotificationType(dispatch, 'reminder')
      setOnView('reminder')
    }, 500)
  }

  const notificationType = {
    reminder: <ReminderNotification onClose={handleCloseReminder} messages={messages} visible={visible} />,
    news: <NewsNotification onClose={handleCloseNews} messages={dataNotification} visible={visible} />
  }
  return notificationType[onView]
}

Notification.propTypes = {
  messages: array,
  userName: string,
  duration: number,
  router: object,
  visible: bool
}

export default Notification
