import './style.css'

import { Col, Form, Row, Typography } from 'antd'
import Helmet from 'components/helmet'
import Layout from 'components/layout'
import { useStateDefault, usePrevious } from 'context'
import { forgotPasswordInit, actionTypes } from 'context/auth/action'
import { func, object } from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import Button from 'components/button'
import Input from 'components/input'

const { Title } = Typography

function LoginPage(props) {
  const { t } = useTranslation() // t ->> translate function to show a message by language chosen
  const tKey = 'pages.forgotPassword.'
  const [nextPath, setnextPath] = React.useState('/home')
  const { history } = props
  const [errForgot, loadingForgot, dispatch] = useStateDefault(actionTypes.FORGOT_PASSWORD)
  const loading = loadingForgot
  const handleSubmit = e => {
    e.preventDefault()
    props.form.validateFields((err, values) => {
      if (!err) {
        forgotPasswordInit(dispatch, values)
      } else {
        toast.error(t('default.formRequired'))
      }
    })
  }

  const setPathRedirect = () => {
    const url = new URL(window.location.href)

    const path = url.searchParams.get('from')
    if (path) {
      setnextPath(path)
    }
  }
  const prevLoadingForgot = usePrevious(loadingForgot)
  React.useEffect(() => {
    const url = new URL(window.location.href)
    const path = url.searchParams.get('from')
    if (path && nextPath !== path) {
      setPathRedirect()
    }

    if (!errForgot && !loadingForgot && prevLoadingForgot !== loadingForgot && prevLoadingForgot === true) {
      toast.success('Request reset password has been sent, please check your email')
      props.form.resetFields()
    }
  })

  const { getFieldDecorator } = props.form

  return (
    <Layout>
      <div className={`root-login`}>
        <Helmet>
          <title>{t(`${tKey}pageTitle`)}</title>
        </Helmet>
        <Row type="flex" justify="center" align="middle">
          <Col span={16} md={10} xl={6}>
            <div style={{ position: 'relative', bottom: 34 }}>
              <Title style={{ color: '#fff' }} level={3}>
                Reset your password
              </Title>
              <Typography style={{ color: 'rgba(199, 199, 199, 0.65)' }}>
                The verification email will be sent to the mailbox, please check it.
              </Typography>
            </div>
            <Form onSubmit={handleSubmit} className="login-form">
              <label
                style={{
                  top: -2,
                  color: '#fff',
                  position: 'relative'
                }}
                htmlFor="normal_login_userName"
              >
                {t(`${tKey}email`)}
              </label>
              <Form.Item>
                {getFieldDecorator('employeeEmail', {
                  rules: [
                    {
                      required: true,
                      message: t(`${tKey}form.emailMessageRequired`)
                    },
                    {
                      type: 'email',
                      message: 'The input is not valid e-mail!'
                    }
                  ]
                })(
                  <Input
                    // prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder="jenny@email.com"
                    autoFocus
                  />
                )}
              </Form.Item>

              <Col span={24}>
                <Button style={{ marginTop: 14 }} loading={loading} type="primary" htmlType="submit">
                  {t(`${tKey}resetButton`).toUpperCase()}
                </Button>
                <div
                  style={{
                    textAlign: 'center',
                    color: '#383838',
                    fontSize: '12px',
                    margin: ' 15px 0px'
                  }}
                >
                  <span onClick={() => history.push('/')} style={{ cursor: 'pointer', color: '#bbb' }}>
                    {t(`${tKey}back`)}
                  </span>
                </div>
              </Col>
            </Form>
          </Col>
        </Row>
      </div>
    </Layout>
  )
}

LoginPage.propTypes = {
  loginUser: func,
  form: object,
  history: object
}

const WrappedLoginPage = Form.create({ name: 'normal_login' })(LoginPage)

export default WrappedLoginPage
