import axios from 'axios'
import Cookies from 'js-cookie'
import config from 'config'
import { toast } from 'react-toastify'
import dispatchAction from 'utils/dispatcher'
import getUser from 'utils/userData'

export const actionTypes = {
  USER_LOGIN: 'USER_LOGIN',
  USER_LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  ACTIVATE_ACCOUNT: 'ACTIVATE_ACCOUNT',
  ACTIVATE_ACCOUNT_SUCCESS: 'ACTIVATE_ACCOUNT_SUCCESS',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  RESET_PASSWORD: 'RESET_PASSWORD',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESEND_ACTIVATION: 'RESEND_ACTIVATION',
  RESEND_ACTIVATION_SUCCESS: 'RESEND_ACTIVATION_SUCCESS'
}

export const actionLogout = (dispatch, isLogin = false) => {
  window.localStorage.removeItem('isLogin')
  Cookies.remove('user')
  dispatch({
    type: actionTypes.USER_LOGIN_SUCCESS,
    data: { isLogin, data: null }
  })
}

export const actionLogin = (dispatch, payload) => {
  const url = config.baseUrl + '/api/authenticate'
  const userLogin = async () => {
    const response = await axios.post(url, payload, { timeout: 120000 })
    let { data } = response
    const { jwtToken, menuAccess, positionMs, ...rest } = data
    if (response.status <= 301) {
      const user = {
        token: 'Bearer ' + jwtToken,
        ...rest
      }
      if (data.messages === 'Need To Change Password') {
        user.newPasswordRequired = true
      } else {
        if (data.messages === 'SuccessLogin') {
          if (menuAccess && !menuAccess[0]) {
            const message = 'You dont have any access right, please contact your administrator'
            toast.warn(message)
            throw message
          }
          localStorage.setItem('isLogin', 'true')
          localStorage.setItem('userData', JSON.stringify({ menuAccess, positionMs }))
        }
      }
      Cookies.set('user', user)
      dispatch({
        type: actionTypes.USER_LOGIN_SUCCESS,
        data: { data: user, isLogin: !!jwtToken }
      })
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured during the login')
    }
  }
  dispatchAction(dispatch, actionTypes.USER_LOGIN, userLogin)
}

export const forgotPasswordInit = (dispatch, payload) => {
  const url = config.baseUrl + '/api/account/forgot-password/init'
  const forgot = async () => {
    const response = await axios.post(url, payload, { timeout: 120000 })
    let { data } = response
    if (response.status <= 301) {
      dispatch({
        type: actionTypes.FORGOT_PASSWORD_SUCCESS,
        data: data
      })
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  dispatchAction(dispatch, actionTypes.FORGOT_PASSWORD, forgot)
}

export const activationAccount = (dispatch, payload) => {
  const url = config.baseUrl + '/api/activate'
  const forgot = async () => {
    const response = await axios.post(url, payload, { timeout: 120000 })
    let { data } = response
    if (response.status <= 301) {
      dispatch({
        type: actionTypes.ACTIVATE_ACCOUNT_SUCCESS,
        data: data
      })
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  dispatchAction(dispatch, actionTypes.ACTIVATE_ACCOUNT, forgot)
}

export const forgotPasswordFinish = (dispatch, payload) => {
  const url = config.baseUrl + '/api/account/forgot-password/finish'
  const forgot = async () => {
    const response = await axios.post(url, payload, { timeout: 120000 })
    let { data } = response
    if (response.status <= 301) {
      dispatch({
        type: actionTypes.FORGOT_PASSWORD_SUCCESS,
        data: data
      })
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  dispatchAction(dispatch, actionTypes.FORGOT_PASSWORD, forgot)
}

export const changePassword = (dispatch, payload) => {
  const user = getUser()
  console.log('TCL: changePassword -> user', user)
  const url = config.baseUrl + '/api/account/change-password'
  const forgot = async () => {
    const response = await axios.post(url, payload, { timeout: 120000, headers: { Authorization: user.token } })
    let { data } = response
    if (response.status <= 301) {
      dispatch({
        type: actionTypes.CHANGE_PASSWORD_SUCCESS,
        data: data
      })
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  dispatchAction(dispatch, actionTypes.CHANGE_PASSWORD, forgot)
}

export const resetPasswordDob = (dispatch, payload) => {
  const user = getUser()
  const url = config.baseUrl + '/api/account/reset-password-dob'
  const forgot = async () => {
    const response = await axios.post(url, payload, { timeout: 120000, headers: { Authorization: user.token } })
    let { data } = response
    if (response.status <= 301) {
      dispatch({
        type: actionTypes.RESET_PASSWORD_SUCCESS,
        data: data
      })
      toast.success('Password has been reset successfully')
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  dispatchAction(dispatch, actionTypes.RESET_PASSWORD, forgot)
}

export const resendActivation = (dispatch, payload) => {
  const user = getUser()
  const url = config.baseUrl + '/api/account/resend-activationkey/init'
  const forgot = async () => {
    const response = await axios.post(url, payload, { timeout: 120000, headers: { Authorization: user.token } })
    let { data } = response
    if (response.status <= 301) {
      dispatch({
        type: actionTypes.RESEND_ACTIVATION_SUCCESS,
        data: data
      })
      toast.success('The activation key has been sent to the user mailbox')
    } else {
      const message = response.data && response.data.message
      throw new Error(message || 'An error has been occured')
    }
  }
  dispatchAction(dispatch, actionTypes.RESEND_ACTIVATION, forgot)
}
