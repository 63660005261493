import React from 'react'
import Helmet from 'components/helmet'
import MainLayout, { Layouts, Content, Sidebar, Header, Footer } from 'components/layout'
import { Skeleton } from 'antd'

function LoadingLayoutPage() {
  const titlePage = ''
  return (
    <MainLayout>
      <Helmet>
        <title>Loading.. - Agrodana Futures</title>
      </Helmet>
      <Sidebar />
      <Layouts>
        <Header />
        <Content titlePage={titlePage}>
          <Skeleton active />
        </Content>
        <Footer />
      </Layouts>
    </MainLayout>
  )
}

export default LoadingLayoutPage
