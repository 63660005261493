import { actionTypes } from './action'
import getUser, { isLogin as checkLogin } from 'utils/userData'

const initState = { data: getUser() || null, isLogin: checkLogin() }

export const userReducer = (state = initState, action) => {
  if (action.type === actionTypes.USER_LOGIN_SUCCESS) {
    return {
      ...state,
      ...action.data
    }
  }
  return state
}

export const forgotPasswordReducer = (state = null, action) => {
  if (action.type === actionTypes.FORGOT_PASSWORD_SUCCESS) {
    return {
      data: action.data
    }
  }
  return state
}

export const activationAccountReducer = (state = null, action) => {
  if (action.type === actionTypes.ACTIVATE_ACCOUNT_SUCCESS) {
    return {
      data: action.data
    }
  }
  return state
}

export const changePasswordReducer = (state = null, action) => {
  if (action.type === actionTypes.CHANGE_PASSWORD_SUCCESS) {
    return {
      data: action.data
    }
  }
  return state
}

export const resetPasswordReducer = (state = null, action) => {
  if (action.type === actionTypes.RESET_PASSWORD_SUCCESS) {
    return {
      data: action.data
    }
  }
  return state
}

export const resendActivationReducer = (state = null, action) => {
  if (action.type === actionTypes.RESEND_ACTIVATION_SUCCESS) {
    return {
      data: action.data
    }
  }
  return state
}
