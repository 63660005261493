import React, { Suspense } from 'react'
import { Switch, HashRouter } from 'react-router-dom'
import ProtectedRoute from './protectedRoute'
import CustomRoute from './customRoute'
import { usePrevious, AuthContext } from 'context'
import LoadingPage from 'components/layout/layoutLoading'
import { protectedRoutes, defaultRoutes } from './routes'
import { isLogin, getUserRoutes, getMenuAccess } from 'utils/userData'

function ReactRouter() {
  const userRoutes = isLogin() ? protectedRoutes.filter(route => getUserRoutes().includes(route.path)) : []
  const [loginRoute, setLoginRoute] = React.useState(defaultRoutes)
  const [menuAccess, setMenuAccess] = React.useState([])

  const getPropertyPageByPath = path => menuAccess.filter(value => value.modulePath === path)[0]

  const { login } = React.useContext(AuthContext)
  const prevLogin = usePrevious(login)
  React.useEffect(() => {
    if (login && login !== prevLogin) {
      const routes = [...userRoutes, ...defaultRoutes]
      setLoginRoute(routes)
      !menuAccess[0] && setMenuAccess(getMenuAccess())
    }
  }, [login, menuAccess, prevLogin, userRoutes])

  const memoizedRoutes = React.useMemo(() => loginRoute, [loginRoute])
  return (
    <HashRouter>
      <Suspense fallback={<LoadingPage />}>
        <Switch>
          {memoizedRoutes.map(({ isProtected, Component, path, ...rest }, i) =>
            isProtected ? (
              <ProtectedRoute
                path={path}
                render={props => <Component page={getPropertyPageByPath(path)} {...props} />}
                key={i}
                {...rest}
              />
            ) : (
              <CustomRoute path={path} render={props => <Component {...props} />} key={i} {...rest} />
            )
          )}
        </Switch>
      </Suspense>
    </HashRouter>
  )
}

export default ReactRouter
