import React, { useRef } from 'react'
import { Modal, Carousel, Empty } from 'antd'
import { usePrevious, useStateValue } from 'context'
import Table from 'components/table'
import { notificationCategory } from './constant'
import { array, string, object, number, bool, func } from 'prop-types'
import { useHistory } from 'react-router-dom'
import { toggleViewNotification } from 'context/notification/action'
import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'

const ReminderNotification = ({ messages, visible, onClose }) => {
  const [components, setComponents] = React.useState([])
  const slider = useRef()
  const history = useHistory()
  const [, dispatch] = useStateValue('user')

  const prevMessages = usePrevious(messages)
  React.useEffect(() => {
    if (messages[0] && prevMessages !== messages) {
      setComponents(formattedMessageToComponents(messages))
    }
  }, [formattedMessageToComponents, messages, prevMessages])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function formattedMessageToComponents(data) {
    // callback for mapping table marketing activity
    const cbMarketingActivities = ({ prospectName, prospectEmail, status, prospectId }) => ({
      prospectName,
      prospectEmail,
      status,
      prospectId
    })
    const getTableSettings = ({ moduleTarget, keyId }) => {
      return {
        pagination: false,
        title: () => null,
        columnProperty: [],
        excludeColumns: ['prospectId'],
        onRow: record => {
          return {
            onClick: event => {
              if (event.target.tagName === 'TD') {
                history.push(`/${moduleTarget}?id=${record[keyId]}&status=${record.status}`)
                toggleViewNotification(dispatch, false)
              }
            }
          }
        }
      }
    }
    /**
     * methods for create custom component for each slides
     *
     * */
    const createComponents = {
      /**
       * reminder if the user not update marketing activity during certain time
       * */
      [notificationCategory.NO_UPDATE]: function createComponent(list) {
        let durationLength = 0
        const dataTable = list.map(({ prospectName, prospectEmail, status, duration, prospectId }) => {
          durationLength = duration
          return { prospectName, prospectEmail, status, prospectId }
        })
        return (
          <div className="notification-table-wrapper">
            <p>{`Dikarenakan status client Anda tidak ada perubahan dalam 
              ${durationLength} hari, mohon updatenya untuk client berikut:`}</p>
            <Table
              {...getTableSettings({ moduleTarget: 'marketing-schedule', keyId: 'prospectId' })}
              data={dataTable}
            />
          </div>
        )
      },
      /**
       * reminder if the user not create a meeting plan
       * */
      [notificationCategory.CREATE_MEETING]: function createComponent(list) {
        const dataTable = list.map(cbMarketingActivities)
        return (
          <div className="notification-table-wrapper">
            <p>{`Mohon mempersiapkan meeting untuk prospect berikut:`}</p>
            <Table
              {...getTableSettings({ moduleTarget: 'marketing-schedule', keyId: 'prospectId' })}
              data={dataTable}
            />
          </div>
        )
      },
      /**
       * reminder if the user has a meeting to go
       * */
      [notificationCategory.BEFORE_MEETING]: function createComponent(list) {
        const dataTable = list.map(cbMarketingActivities)
        return (
          <div className="notification-table-wrapper">
            <p>{`Meeting Reminder untuk:`}</p>
            <Table
              {...getTableSettings({ moduleTarget: 'marketing-schedule', keyId: 'prospectId' })}
              data={dataTable}
            />
          </div>
        )
      },
      /**
       * reminder if the user has client ready to demo
       * */
      [notificationCategory.DEMO_TRADING]: function createComponent(list) {
        const dataTable = list.map(cbMarketingActivities)
        return (
          <div className="notification-table-wrapper">
            <p>{`Demo Reminder untuk:`}</p>
            <Table
              {...getTableSettings({ moduleTarget: 'marketing-schedule', keyId: 'prospectId' })}
              data={dataTable}
            />
          </div>
        )
      },
      /**
       * reminder if the user have client that will be birthday soon
       * */
      [notificationCategory.CLIENT_BIRTHDAY]: function createComponent(list) {
        const dataTable = list.map(cbMarketingActivities)
        return (
          <div className="notification-table-wrapper">
            <p>{`Birthday Reminder:`}</p>
            <SimpleBar style={{ maxHeight: 400 }}>
              <Table
                {...getTableSettings({ moduleTarget: 'marketing-schedule', keyId: 'prospectId' })}
                data={dataTable}
              />
            </SimpleBar>
          </div>
        )
      }
    }

    /**
     * map dynamic components
     */
    const components = data.map(({ key, list }) => {
      if (key && createComponents[key]) {
        return createComponents[key](list)
      }
    })
    return components
  }

  return (
    <Modal
      title={`Reminder`}
      okText={`Next`}
      onOk={() => slider.current.next()}
      cancelText={`Close`}
      onCancel={() => {
        toggleViewNotification(dispatch, false)
        onClose()
      }}
      visible={visible}
      destroyOnClose={true}
      className="notification"
      closable={false}
      maskClosable={false}
      okButtonProps={{
        disabled: components.length === 1
      }}
    >
      {components.length ? (
        <Carousel
          ref={ref => {
            slider.current = ref
          }}
          infinite={true}
          dotPosition="top"
        >
          {components}
        </Carousel>
      ) : (
        <Empty />
      )}
    </Modal>
  )
}

ReminderNotification.propTypes = {
  messages: array,
  userName: string,
  duration: number,
  router: object,
  visible: bool,
  onClose: func
}

export default ReminderNotification
